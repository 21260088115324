body .col .col_wrap {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
  min-height: 200px;
  padding: 50px;
  position: relative;
}
body .col .Link {
  position: absolute;
  width: 100%;
  height: 100%;
}
body .col .col_wrap h3 {
  color: #001c28;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

body .col .col_wrap h4 {
  color: #001c28;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  margin: 16px 0;
}
body .col .col_wrap p {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
body .col .col_wrap h6 {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 22px 0;
}
body .col .col_wrap h6 span {
  font-weight: 700;
}
.small_box .key {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
.small_box {
  border-radius: 5px;
  background: #f1f5f6;
  display: inline-block;
  min-height: 39px;
  flex-wrap: wrap;
  padding: 15px;
  margin-bottom: 13px;
}
.small_box:not(:last-child) {
  margin-right: 13px;
}
.small_box_wrap {
  display: flex;
  flex-wrap: wrap;
}
.small_box .value {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  display: block;
  width: 100%;
  text-align: center;
}

.edit_wrap button {
  width: 21px;
  height: 21px;
  border-radius: 2px;
  background: #f2f2f2;
  min-width: auto;
  margin-left: 19px;
  display: inline-block;
  padding: 0;
}
.edit_wrap button a {
  width: 21px;
  height: 21px;
  display: inline-block;
}
