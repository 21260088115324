.topskillsMain {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
  padding: 25px;
  background: #fff;
  overflow: hidden;
  width: 100%;
}
.topskillsMain .fs_h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
}
body .topskillsMain .Area {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.topskillsMain .Area::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: rgba(56, 55, 56, 1);
  display: inline-block;
  margin-right: 10px;
}
.topskillsMain .StudentCount {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

body .totalJobsContainer_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.totalJobsContainer_ .btn_wrapper {
  display: flex;
  align-content: center;
}
.totalJobsContainer_ h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
}
.totalJobsContainer_ .btns {
  border-radius: 5px;
  background: #f1f5f6;
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.4px;
  margin-right: 10px;
  padding: 11px 0;
  transition: all 0.1s;
  min-width: 92px;
  width: 100%;
}
.totalJobsContainer_ .btns:last-child {
  margin-right: 0;
}
.totalJobsContainer_ .btns.active,
.totalJobsContainer_ .btns:hover {
  background: #c71903;
  color: #fff;
  font-weight: bold;
}
.chart_wrap {
  height: 380px;
  display: flex;
  margin-top: 30px;
}
@media screen and (max-width: 1800px) {
  .totalJobsContainer_ .btns {
    max-width: 93.42px;
  }
}
@media screen and (max-width: 1700px) {
  body .totalJobsContainer_ {
    display: block;
  }
  body .totalJobsContainer_ .btn_wrapper {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1450px) {
  .totalJobsContainer_ .btns {
    max-width: 91.42px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 1199px) {
  body .totalJobsContainer_ {
    display: flex;
  }
}
