body .fs14 {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  line-height: 16.8px;
}
body .courseOutLineForm input {
  border-radius: 5px;
  border: 2px solid rgb(0 28 40 / 12%);
  padding: 8.2px 15px;
}
body .courseOutLineForm .upload_file {
  border-radius: 5px;
  background: #001c28;
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 9px 15px;
}

body .heading {
  color: #001c28;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
body .addOutline {
  border-radius: 5px;
  background: #f2f2f2;
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-shadow: none;
  padding: 11.1px 15px;
}
body .addOutline:hover {
  background: #f2f2f2;
  box-shadow: none;
  opacity: 0.8;
}
body .ChipSet {
  background-color: #001c28;
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-right: 5px;
  cursor: pointer;
}
body .ChipSet span {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15.6px;
}
body .ChipSet svg {
  color: #fff;
  font-size: 12px;
}
.courseOutlineTable {
  padding-left: 20px;
  padding-right: 20px;
}
.courseOutlineTable .viewMore {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  margin-left: 10px;
  cursor: pointer;
}
.chipModal .ChipSet {
  margin-bottom: 10px;
  cursor: pointer;
}

body .sectionMedia .ChipSet {
  background-color: rgba(248, 248, 248, 1);
  color: black;
}
