.popup_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1280px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  max-height: 68vh;
  overflow-y: auto;
}
.flexform {
  display: flex;
  margin-bottom: 50px;
}
.flexformfield {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 10px;
}

.stepone {
  width: 50%;
  border-right: 1px solid;
  padding-right: 20px;
  border-color: rgba(217, 217, 217, 1);
}

.steptwo {
  width: 50%;
}
.margin {
  margin: 0px 30px 0px !important;
  color: #001c28;
  font-size: 14px;
}
.seperatemargin {
  margin: 10px 30px 50px !important;
  width: 81% !important;
}
.plusbtn {
  background-color: #c33f28 !important;
  position: relative !important;
  bottom: -9px !important;
  padding: 8px 20px !important;
  min-width: 0 !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  left: -10px !important;
}

.minusbtn {
  color: #004c6e !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  background-color: rgba(0, 76, 110, 0.19) !important;
  min-width: 0 !important;
  padding: 2px 10px !important;
  position: relative !important;
  top: -40px !important;
  left: -24px !important;
}
.minusbtn:hover {
  background-color: rgba(0, 76, 110, 0.19);
}
.fieldflex {
  display: flex;
}
.fieldflexheading {
  font-size: 14px;
  color: #001c28;
}

.close_btn {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
  right: 30px;
  font-size: 0;
  border: 0;
  background: transparent;
  opacity: 1 !important;
  cursor: pointer;
}
.close_btn:before {
  cursor: pointer;
  content: "";
  background-image: url("../../../static/images/times.png");
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0;
  top: 0;
}
