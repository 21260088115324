.stu_report_wrap {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 9px 0px rgba(37, 10, 43, 0.05);
  border-radius: 10px;
  padding: 35px;
}

.filter_flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
/* .filter_btn {
  margin-right: 20px;
} */
.filter_btna {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 70px 8px 10px;
  text-decoration: none;
  color: #000;
  position: relative;
}
.filter_btna:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD7SURBVHgB7ZM9DoJAEIXfCiZWhoLCgoKSW4BH8AZ6A28gnsR4EvAGlHRSWFhYoLVmnV0g4W+RII2JX7GzbN68zDI7DJ+wHI/WIP9a4hKHXfIJRuZv+BOGC8empxLIqKJDUzW0bQNT+eY8GdtMxVlZY1CO0jBJUnAc5Z4jSxSxoH4mtCnllGBow3J8Wne5hBK40aLa09T49UMNbTxuIeYmk9cCZn3N1IZN015mApYPP/BEgmucNBSV6yvMRKP07L9qVMWZ4prac5dVqSs9KSszzC2tB+Gjow8dV6wz+qSUK3Tz/zUEt9gwOULNTg6Ds2hC3d3QLsL3hHjx1Rvxok9bQJV+ugAAAABJRU5ErkJggg==);
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
}
/* .filter_btna {
    margin-right: 20px;
  } */
.back_btn {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 0;
  text-decoration: none;
  color: #fff;
  position: relative;
  display: inline-block;
  background-color: #001c28;
  min-width: 170px;
  margin-right: 15px;
  text-align: center;
}
.filter_btna_csv {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 36px 8px 10px;
  text-decoration: none;
  color: #fff;
  position: relative;
  display: inline-block;
  background-color: #001c28;
  min-width: 170px;
}
.filter_btna_csv:before {
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M13.125 10.3125V13.125H1.875V10.3125H0V13.125C0 14.1562 0.84375 15 1.875 15H13.125C14.1562 15 15 14.1562 15 13.125V10.3125H13.125ZM12.1875 6.5625L10.8656 5.24063L8.4375 7.65938V0H6.5625V7.65938L4.13437 5.24063L2.8125 6.5625L7.5 11.25L12.1875 6.5625Z" fill="white"/></svg>');
  position: absolute;
  right: 10px;
  width: 15px;
  height: 15px;
  top: 11px;
}
.filter_btna_black_csv {
  background-color: #000;
  color: #fff;
}

.filter_btna_csv.filter_btna_black:before {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}
