body .out_of_box {
  position: absolute ;
  top: 64px;
  background-color: #e1ebed ;
}
body .out_of_box p {
  color: #064560;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 12px */
}
/* .assignment_detail .rdt_TableBody {
  margin-top: 60px;
} */
/* .assignment_detail .rdt_TableHead:before {
  content: "";
  width: 100%;
  height: 50px;
  background-color: #e1ebed;
  position: absolute;
  top: 52px;
} */
.assignments_name {
  display: flex;
  flex-direction: column;
}
.assignments_name button {
  background-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
}
.assignments_name button svg {
  font-size: 15px;
  margin-left: 5px;
}
.assignment_detail{
  padding-bottom: 0;
}


.totalMarks , .markObtain{
  font-weight: 400;
  color: black;
  font-size: 14px;
}
.percentage{
  font-weight: 700;
}

 
/* ==== used in all files ==== */

.fontStyleName {
  color: #001C28;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
};

.fontStyle {
  color: #001C28;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: red
};



