.courseDetailFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 20px 50px;
}
.courseDetailFlex .MuiBox-root {
  width: 50%;
  text-align: end;
}
.courseDetailFlexHeading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.courseDetailFlexHeading span,
.courseDetailFlexHeading h2 {
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 40px;
}
.courseDetailFlexHeading span {
  font-size: 14px;
  color: #001c28;
}
.courseDetailFlexHeading h2 {
  font-size: 30px;
  color: #001c28;
}
.coude {
  font-size: 16px;
  color: #001c28;
  font-weight: 400;
  display: flex;
}
.flex_box {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-top: 50px;
}
