.workshop_attendence_table {
  margin-top: 100px;
}
body .text_style {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
body .bold {
  font-weight: 700;
}

.workshop_attend_wrap {
  margin-top: 10px;
}
.workshop_attend_wrap .date {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
.workshop_attend_wrap .date span {
  display: block;
  color: #001c28;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
}
.workshop_attend_wrap .attend_table thead tr {
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
}
.workshop_attend_wrap .attend_table thead tr th {
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.6px;
}
.workshop_attend_wrap .attend_table thead tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.workshop_attend_wrap .attend_table thead tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.workshop_attend_wrap .attend_table {
  margin-top: 20px;
  box-shadow: none;
}
.workshop_attend_wrap .attend_table tbody tr {
  box-shadow: none;
}
.workshop_attend_wrap .attend_table tbody tr td:first-child {
  padding-left: 15px;
}
.workshop_attend_wrap .attend_table tbody tr td:last-child {
  padding-right: 15px;
}
.workshop_attend_wrap .attend_table tbody tr td {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  padding: 5px 0;
}
.workshop_attend_wrap
  .attend_table
  .select_box_wrap
  [class*="MuiSelect-select"] {
  padding: 10px 0;
  text-align: left;
  padding-left: 19px;
}
.workshop_attend_wrap
  .attend_table
  .select_box_wrap
  [class*="MuiInputBase-root"] {
  width: 100%;
}
.workshop_attend_form button {
  margin-right: 0;
  margin-top: 20px;
}
