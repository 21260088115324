.out_of_box {
  position: absolute;
  top: 65px;
  background-color: #e1ebed;
}
.out_of_box p {
  color: #064560;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 12px */
}
.assignment_detail.assignment_detail_inner .rdt_TableBody {
  margin-top: 60px;
}
.assignment_detail.assignment_detail_inner .rdt_TableHead:before {
  content: "";
  width: 100%;
  height: 50px;
  background-color: #e1ebed;
  position: absolute;
  top: 52px;
}
.assignments_name {
  display: flex;
  flex-direction: column;
}
.assignments_name button {
  background-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
}
.assignments_name button svg {
  font-size: 15px;
  margin-left: 5px;
}
.tableassignment_detail {
  /* margin: 0 30px; */
  padding-bottom: 0;
}
.assinDetailheading {
  color: #001c28;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.assinDetailheading span {
  color: #001c28;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
