body .fs_h3 {
  color: #001c28;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
}
body .view_btn {
  border-radius: 5px;
  background: #001c28;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  width: 117.014px;
  padding: 10px 0;
}

body .table_wraper {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 9px 0px rgba(37, 10, 43, 0.05);
  padding: 42px;
  width: 100%;
}
body .table_wraper [class*="table-phases"] {
  padding-bottom: 0;
}
