.upload_file_wrap {
  border-radius: 5px;
  border: 2px dashed #001c28;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
  max-width: 278px;
  margin: 0 auto;
  margin-top: 9px;
}
.upload_file_wrap p {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  min-width: 130px;
  line-height: normal;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.upload_file_wrap .u_button {
  border-radius: 5px;
  background: #001c28;
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 9px;
  min-width: 87px;
}
.upload_file_wrap .u_button:hover {
  background: #001c28;
  color: #fff;
  opacity: 0.8;
}

.apply_form_wrap h3 {
  color: #001c28;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.apply_form_wrap h6 {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
