/* CSS for .studentTopFilterMain */
.studentTopFilterMain {
  display: flex;
  align-items: center;
  position: relative;
}
.studentTopFilterMain [class*="react-datepicker__input-container"] {
  margin-top: 0;
  padding-right: 0;
  width: 100%;
}
.studentTopFilterMain [class*="react-datepicker-wrapper"] {
  margin-right: 20px;
  width: 100%;
  max-width: 150px;
}
.studentTopFilterMain [class*="react-datepicker__input-container"] input {
  width: 100%;
  font-size: 14px;
  font-family: "Poppins";
  background: #fff;
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 25px;
  height: 49px;
  color: #001c28;
}

.studentTopFilterMain .studentTopFilterMainTitle {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  color: #001c28;
  margin-right: 23px;
}

.studentTopFilterMain .studentTopFilterId {
  width: 150px;
  margin-right: 10px;
}
.studentTopFilterMain .studentTopFilterId .studentTopFilterIdInput input {
  padding: 12.5px 18px;
  font-size: 14px;
  font-family: "Poppins";
  background: #fff;
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
}

.studentTopFilterMain .studentTopFilterId .studentTopFilterIdInput fieldset {
  border: none;
}

.studentTopFilterMain .studentTopFilterLvl {
  width: 150px;
  margin-right: 10px;
}

.studentTopFilterMain .studentTopFilterLvl .studentTopFilterLvlInput div {
  padding: 11px 18px;
  font-size: 14px;
  font-family: "Poppins";
  background: #fff;
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
}

.studentTopFilterMain .studentTopFilterLvl .studentTopFilterLvlInput fieldset {
  border: none;
}

.studentTopFilterMain .studentTopFilterPlace {
  width: 150px;
  margin-right: 10px;
}

.studentTopFilterMain .studentTopFilterPlace .studentTopFilterPlaceInput div {
  padding: 11px 18px;
  font-size: 14px;
  font-family: "Poppins";
  background: #fff;
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
}

.studentTopFilterMain
  .studentTopFilterPlace
  .studentTopFilterPlaceInput
  fieldset {
  border: none;
}

.studentTopFilterMain .studentTopFilterKeyword {
  display: flex;
  align-items: center;
}

.studentTopFilterMain .studentTopFilterKeyword .studentTopFilterKeywordInput {
  width: 250px;
  margin-right: 15px;
}

.studentTopFilterMain
  .studentTopFilterKeyword
  .studentTopFilterKeywordInput
  input {
  padding: 12.5px 18px;
  font-size: 14px;
  font-family: "Poppins";
  background: #fff;
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
}

.studentTopFilterMain
  .studentTopFilterKeyword
  .studentTopFilterKeywordInput
  fieldset {
  border: none;
}

.studentTopFilterMain .studentTopFilterKeyword .studentTopFilterBtn {
  width: 60px;
  display: flex;
  align-items: center;
}

.studentTopFilterMain
  .studentTopFilterKeyword
  .studentTopFilterBtn
  .studentTopFilterBtnContent {
  padding: 10.5px 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Poppins";
  background: #000;
  width: 100%;
  color: #fff;
  border-radius: 5px;
}

.studentTopFilterMain .studentTopFilterAccen {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 160px;
}

.studentTopFilterMain .studentTopFilterAccen .studentTopFilterAccenInput div {
  padding: 11px 18px;
  font-size: 14px;
  font-family: "Poppins";
  background: #fff;
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
}

.studentTopFilterMain
  .studentTopFilterAccen
  .studentTopFilterAccenInput
  fieldset {
  border: none;
}

/* Responsive Media */
@media screen and (max-width: 1667px) {
  /* Base Filter */
  .baseFilterMain
    .studentTopFilterMain
    .studentTopFilterKeyword
    .studentTopFilterKeywordInput {
    width: 100%;
  }

  /* Top Student Filter */
  .studentTopFilterMain .studentTopFilterMainTitle {
    margin-right: 10px;
  }

  .studentTopFilterMain .studentTopFilterId {
    width: 120px;
  }

  .studentTopFilterMain .studentTopFilterId .studentTopFilterIdInput input {
    padding: 10px 18px;
  }

  .studentTopFilterMain .studentTopFilterLvl {
    width: 130px;
  }

  .studentTopFilterMain .studentTopFilterLvl .studentTopFilterLvlInput div {
    padding: 8.5px 18px;
  }

  .studentTopFilterMain .studentTopFilterPlace .studentTopFilterPlaceInput div {
    padding: 8.5px 18px;
  }

  .studentTopFilterMain .studentTopFilterKeyword .studentTopFilterKeywordInput {
    width: 160px;
    margin-right: 5px;
  }

  .studentTopFilterMain
    .studentTopFilterKeyword
    .studentTopFilterKeywordInput
    input {
    padding: 10px 18px;
  }

  .studentTopFilterMain .studentTopFilterBtn .studentTopFilterBtnContent {
    padding: 10px 0px;
  }

  .studentTopFilterMain .studentTopFilterAccen {
    width: 150px;
  }

  .studentTopFilterMain .studentTopFilterAccen .studentTopFilterAccenInput div {
    padding: 10px 18px;
  }
}

@media screen and (max-width: 1440px) {
  /* Base Filter */
  .baseFilterMain
    .studentTopFilterMain
    .studentTopFilterKeyword
    .studentTopFilterKeywordInput {
    width: 100%;
  }

  /* Top Student Filter */
  .studentTopFilterMain .studentTopFilterId {
    width: 80px;
  }

  .studentTopFilterMain .studentTopFilterId .studentTopFilterIdInput input {
    padding: 10px 16px;
  }

  .studentTopFilterMain .studentTopFilterLvl {
    width: 120px;
  }

  .studentTopFilterMain .studentTopFilterLvl .studentTopFilterLvlInput div {
    padding: 8.5px 12px;
  }

  .studentTopFilterMain .studentTopFilterPlace .studentTopFilterPlaceInput div {
    padding: 8.5px 12px;
  }

  .studentTopFilterMain .studentTopFilterKeyword .studentTopFilterKeywordInput {
    width: 150px;
    margin-right: 5px;
  }

  .studentTopFilterMain
    .studentTopFilterKeyword
    .studentTopFilterKeywordInput
    input {
    padding: 10px 12px;
  }

  .studentTopFilterMain .studentTopFilterBtn .studentTopFilterBtnContent {
    padding: 9px 0px;
  }

  .studentTopFilterMain .studentTopFilterAccen {
    width: 140px;
  }

  .studentTopFilterMain .studentTopFilterAccen .studentTopFilterAccenInput div {
    padding: 10px 16px;
  }
}

@media screen and (max-width: 1280px) {
  /* Base Filter */
  .baseFilterMain .studentTopFilterMain .studentTopFilterKeyword {
    /* width: calc(100% - 100px); */
  }

  .baseFilterMain
    .studentTopFilterMain
    .studentTopFilterBtn
    .studentTopFilterBtnContent {
    width: 150px !important;
  }

  /* Top Student Filter */
  .studentTopFilterMain {
    /* flex-wrap: wrap; */
    /* padding-bottom: 60px; */
  }

  .studentTopFilterMain .studentTopFilterMainTitle {
    width: 100px;
    margin-right: 10px;
  }

  .studentTopFilterMain .studentTopFilterId {
    width: calc(50% - 55px);
    margin-right: 10px;
  }

  .studentTopFilterMain .studentTopFilterLvl {
    width: calc(50% - 55px);
    margin-right: 0px;
  }

  .studentTopFilterMain .studentTopFilterPlace {
    width: calc(50% - 55px);
    margin: 10px 10px 0px 100px;
  }

  .studentTopFilterMain .studentTopFilterKeyword {
    /* flex-wrap: wrap; */
    position: relative;
    width: 100%;
    margin: 10px 0px 0px;
  }

  .studentTopFilterMain .studentTopFilterKeyword .studentTopFilterKeywordInput {
    width: 100%;
    margin-right: 5px;
  }

  /* .studentTopFilterMain .studentTopFilterKeyword .studentTopFilterBtn {
    position: absolute;
    padding: 0px;
    width: 100%;
    bottom: -56px;
  } */

  .studentTopFilterMain
    .studentTopFilterKeyword
    .studentTopFilterBtn
    .studentTopFilterBtnContent {
    width: calc(100% - 0px);
  }

  .studentTopFilterMain .studentTopFilterAccen {
    top: inherit;
    transform: translate(0%, 0%);
    bottom: 0px;
  }
}
.spanor {
  margin-right: 10px;
  font-weight: 700;
}
