
.popup{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 649px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  padding: 32px;
}

.popup .close_btn{
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
  right: 30px;
  font-size: 0;
  border: 0;
  background: transparent;
  opacity: 1!important;
  cursor: pointer;
}
.popup .close_btn:before {content: "";background-image: url("../../../static/images/times.png");position: absolute;width: 16px;height: 16px;right: 0;top: 0;}

.shift_module input{
  padding: 10px 14px;
  height: auto;
}
.shift_module .days_btn button{
  background-color: #FFF;
  border-radius: 5px;
  border: 2px solid #001C28;
  color: #001C28;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  padding: 9px 0;
  max-width: 149px;
}
.shift_module .days_btn button.active{
  background-color: #001C28;
  color: #FFF;
}
.select_time > div {
 padding-top: 10px;
 padding-bottom: 10px;
}

