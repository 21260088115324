.topskillsMain {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
  padding: 25px;
  background: #fff;
  overflow: hidden;
  width: 100%;
}
.topskillsMain .fs_h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
}
