body .attemdence_summray_title {
  color: #001c28;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
}
body .attemdence_summray_title span {
  font-size: 13px;
}
body .attemdence_summray_percent {
  color: #20d568;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
