.display_left {
  display: flex;
  justify-content: space-between;
}
.display_right {
  display: flex;
}

.small_box .key {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
.small_box {
  border-radius: 5px;
  background: #f1f5f6;
  width: 100%;
  max-width: 143px;
  display: inline-block;
  min-height: 39px;
  flex-wrap: wrap;
  padding: 10px;
  margin-right: 13px;
}
.small_box .value {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  display: block;
  width: 100%;
  text-align: center;
}
.edit_wrap button {
  width: 21px;
  height: 21px;
  border-radius: 2px;
  background: #f2f2f2;
  min-width: auto;
  margin-left: 19px;
}
.pagination_wrap ul li button {
  min-width: 26px;
  height: 26px;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  background-color: #e2eaec99;
  border: 0;
  color: #001c28;
}
.pagination_wrap ul li button[class*="Mui-selecte"] {
  background-color: #c33f28;
  color: #fff;
}
.count {
  color: #001c28;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px;
}

/* edit */
.job_form [class*="btn-flex"] {
  justify-content: flex-start;
  margin-top: 42px;
}
.job_form [class*="MuiTextarea-root"] {
  border-color: rgba(0, 0, 0, 0.23);
}
.job_form .company_overview {
  max-width: 95px;
  display: inline-block;
  overflow: inherit;
}
.job_type {
  padding-left: 10px;
}

@media screen and (max-width: 1360px) {
  .display_left {
    display: block;
  }
  .display_right {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .job_form .company_overview {
    max-width: 100%;
  }
  .job_type {
    padding-left: 0;
  }
}
