.report_wrap {
  box-shadow: 0px 3px 9px 0px #250a2b0d;
  padding: 30px;
  border-radius: 5px;
}

.course_content h1 {
  padding: 0 30px;
}
.course_content h1 {
  margin: 0;
  margin-bottom: 25px;
}
body .table_wrap {
  box-shadow: none;
  overflow-x: unset;
}
.report_wrap .accordion_wrap {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 12px 14px 0px rgba(37, 10, 43, 0.02);
  margin-bottom: 25px;
  position: relative;
}
.report_wrap
  .accordion_wrap
  [class*="MuiAccordionSummary-expandIconWrapper"]
  [class*="MuiSvgIcon-root"] {
  display: none;
}
.report_wrap .accordion_wrap [class*="MuiAccordionSummary-root"] {
  background-color: #f5f6f7;
  border-radius: 5px;
}
.report_wrap .accordion_wrap [class*="Mui-expanded"] {
  background-color: #001c28;
  min-height: auto;
  color: #fff;
}
.report_wrap .accordion_wrap [class*="MuiAccordionDetails-root"] {
  padding: 25px;
}
.report_wrap .accordion_wrap [class*="MuiAccordionDetails-root"] label span,
.report_wrap .accordion_wrap [class*="MuiAccordionDetails-root"] label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #001c28;
}
.report_wrap .accordion_wrap [class*="MuiAccordionSummary-root"] {
  padding: 0;
}
.report_wrap .accordion_wrap::before {
  display: none;
}

body .accordion_wrap [class*="MuiAccordionSummary-content"] {
  align-items: center;
  margin: 15px 0;
  padding: 0 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  justify-content: space-between;
}
body .accordion_wrap .section_selected_item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
}
body .accordion_wrap .section_selected_label {
  font-weight: 400;
}
.report_wrap .table_row {
  border-radius: 5px;
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
  box-shadow: 0px 34px 64px 0px rgba(0, 0, 0, 0.08);
}
.report_wrap .table_row th {
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 15.6px */
}
body .report_wrap td {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #001c28;
}
.Genrate_Now_wrap {
  width: 100%;
  text-align: right;
}
.Genrate_Now_wrap button:disabled,
.Genrate_Now_wrap button {
  background: #c33f28;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #fff;
  padding: 13px 17px;
  margin-top: 50px;
}
.Genrate_Now_wrap button:disabled {
  padding-right: 45px;
}

.Genrate_Now_wrap button:hover {
  background: #c33f28;
  opacity: 0.8;
}
