.symbol {
  position: absolute;
  top: 12px;
  right: 15px;
  font-family: Poppins !important;
  font-size: 13px;
  color: #8f979b;
}
.symbol_wrap {
  position: relative;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 649px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  overflow-y: auto;
  max-height: 80vh;
}
.popup:focus-visible {
  outline: none;
}
