/* Teacher Card */
.teacherProfileList {
  display: flex;
  align-content: center;
  background-color: #fff;
}

.teacherCardBx {
  padding: 28px 60px 39px;
  border-radius: 0px;
  box-shadow: none;
}

.teacherCardContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0px 21px;
  max-width: 160px;
}

.stuProfile {
  width: 155px;
  height: 155px;
  margin-bottom: 25px;
}

.stuName {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  font-family: poppins;
  text-transform: capitalize;
}

.stuName span span {
  color: #4e4e4e;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  display: block;
  margin-top: 5px;
}

.stuLastActive {
  font-size: 13px;
  font-weight: 400;
  font-family: poppins;
}

.stuLastActive b {
  font-weight: 700;
}

.stuViewDetailBtn {
  display: flex;
  position: relative;
  justify-content: center;
}

.stuViewDetailCheck {
  position: absolute;
  width: 100%;
  z-index: 99;
  opacity: 0;
}

.stuViewBtn {
  background-color: #000 !important;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 700 !important;
}

.gridItem {
  border-top: 1px solid #edeced;
  border-left: 1px solid #edeced;
  padding: 25px 40px;
}

.gridItem h3 {
  color: #4e4e4e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 15px;
}

.gridItem span {
  color: #4e4e4e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-right: 40px;
}

.gridItem span span {
  font-weight: 700;
  margin-left: 10px;
}

.filter_flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
/* .filter_btn {
  margin-right: 20px;
} */
.filter_btna {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 70px 8px 10px;
  text-decoration: none;
  color: #000;
  position: relative;
}
.filter_btna:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD7SURBVHgB7ZM9DoJAEIXfCiZWhoLCgoKSW4BH8AZ6A28gnsR4EvAGlHRSWFhYoLVmnV0g4W+RII2JX7GzbN68zDI7DJ+wHI/WIP9a4hKHXfIJRuZv+BOGC8empxLIqKJDUzW0bQNT+eY8GdtMxVlZY1CO0jBJUnAc5Z4jSxSxoH4mtCnllGBow3J8Wne5hBK40aLa09T49UMNbTxuIeYmk9cCZn3N1IZN015mApYPP/BEgmucNBSV6yvMRKP07L9qVMWZ4prac5dVqSs9KSszzC2tB+Gjow8dV6wz+qSUK3Tz/zUEt9gwOULNTg6Ds2hC3d3QLsL3hHjx1Rvxok9bQJV+ugAAAABJRU5ErkJggg==);
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
}

.popup_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1280px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}
.flexform {
  display: flex;
  margin-bottom: 50px;
}
.flexformfield {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 10px;
}

.stepone {
  width: 50%;
  border-right: 1px solid;
  padding-right: 20px;
  border-color: rgba(217, 217, 217, 1);
}

.steptwo {
  width: 50%;
}
.margin {
  margin: 0px 30px 0px !important;
  color: #001c28;
  font-size: 14px;
}
.seperatemargin {
  margin: 10px 30px 50px !important;
  width: 81% !important;
}
.plusbtn {
  background-color: #c33f28 !important;
  position: relative !important;
  bottom: -16.3px !important;
  padding: 8px 20px !important;
  min-width: 0 !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  left: -10px !important;
}

.minusbtn {
  color: #004c6e !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  background-color: rgba(0, 76, 110, 0.19) !important;
  min-width: 0 !important;
  padding: 2px 10px !important;
  position: relative !important;
  top: -40px !important;
  left: -24px !important;
}
.minusbtn:hover {
  background-color: rgba(0, 76, 110, 0.19);
}
.fieldflex {
  display: flex;
}
.fieldflexheading {
  font-size: 14px;
  color: #001c28;
}

.close_btn {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
  right: 30px;
  font-size: 0;
  border: 0;
  background: transparent;
  opacity: 1 !important;
  cursor: pointer;
}
.close_btn:before {
  cursor: pointer;
  content: "";
  background-image: url("../../../static/images/times.png");
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0;
  top: 0;
}

.courseDetailFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 20px 50px;
}
.courseDetailFlex .MuiBox-root {
  width: 50%;
  text-align: end;
}
.courseDetailFlexHeading {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.courseDetailFlexHeading > span,
.courseDetailFlexHeading h2 {
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 40px;
}
.courseDetailFlexHeading span {
  font-size: 14px;
  color: #001c28;
  display: flex;
  align-items: center;
}
.courseDetailFlexHeading h2 {
  font-size: 30px;
  color: #001c28;
}
.coude {
  font-size: 16px;
  color: #001c28;
  font-weight: 400;
  overflow-wrap: anywhere;
  /* margin-bottom: 20px; */
}
.bold {
  margin-top: 20px;
}
.coufee {
  font-size: 27px;
  color: #001c28;
  font-weight: 700;
}
.couViewBtn {
  text-decoration: none !important;
  font-weight: 700 !important;
  color: #fff !important;
  border-radius: 4px;
  margin: 15px 0px 0 0 !important;
  padding: 15px 30px;
  background: #c33f28;
  text-transform: capitalize;
}
body .action_button {
  font-size: 0;
  min-width: 22px;
  padding: 11px;
  display: inline-block;
  margin: 0 5px;
}

.action_del:before {
  content: "";
  position: absolute;
  background-image: url("../../../static/images/delete.png");
  width: 22px;
  height: 22px;
  left: 0;
  top: 0;
}

.action_edit:before {
  content: "";
  position: absolute;
  background-image: url("../../../static/images/edit.png");
  width: 22px;
  height: 22px;
  left: 0;
  top: 0;
}

.ASDDetailBtnText {
  border-radius: 5px;
  background: #001c28;
  text-transform: capitalize;
}
.boxModuleChange {
  border-radius: 5px;
  border: 2px dashed rgb(0 28 40 / 12%);
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.rating {
  margin-right: 18px !important;
  margin-bottom: 0;
  margin-top: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.courseDetailFlexContent {
  width: 80%;
}
.courseDetailSecond {
  text-align: end;
}

body .staus_active {
  border-radius: 8px;
  background: #d9d9d9;
  color: #001c28;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 50px;
  width: 100%;
  text-align: center;
  padding: 1px 0;
}


@media(max-width:1280px){
  .couViewBtn {
    padding: 12px;
  }
}
