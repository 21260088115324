/* .filter_btna {
    margin-right: 20px;
  } */
.filter_btna {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 36px 8px 10px;
  text-decoration: none;
  color: #fff;
  position: relative;
  display: inline-block;
  background-color: #001c28;
  min-width: 170px;
  margin-right: 15px;
}
.filter_btna:before {
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M13.125 10.3125V13.125H1.875V10.3125H0V13.125C0 14.1562 0.84375 15 1.875 15H13.125C14.1562 15 15 14.1562 15 13.125V10.3125H13.125ZM12.1875 6.5625L10.8656 5.24063L8.4375 7.65938V0H6.5625V7.65938L4.13437 5.24063L2.8125 6.5625L7.5 11.25L12.1875 6.5625Z" fill="white"/></svg>');
  position: absolute;
  right: 10px;
  width: 15px;
  height: 15px;
  top: 11px;
}
.filter_btna.filter_btna_black {
  background-color: #000;
  color: #fff;
}

.filter_btna.filter_btna_black:before {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}
