.filter_flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.filter_btna {
  border: 2px solid rgba(7, 24, 54, 0.1);
  border-radius: 5px;
  padding: 8px 70px 8px 10px;
  text-decoration: none;
  color: #000;
  position: relative;
}
.filter_btna:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD7SURBVHgB7ZM9DoJAEIXfCiZWhoLCgoKSW4BH8AZ6A28gnsR4EvAGlHRSWFhYoLVmnV0g4W+RII2JX7GzbN68zDI7DJ+wHI/WIP9a4hKHXfIJRuZv+BOGC8empxLIqKJDUzW0bQNT+eY8GdtMxVlZY1CO0jBJUnAc5Z4jSxSxoH4mtCnllGBow3J8Wne5hBK40aLa09T49UMNbTxuIeYmk9cCZn3N1IZN015mApYPP/BEgmucNBSV6yvMRKP07L9qVMWZ4prac5dVqSs9KSszzC2tB+Gjow8dV6wz+qSUK3Tz/zUEt9gwOULNTg6Ds2hC3d3QLsL3hHjx1Rvxok9bQJV+ugAAAABJRU5ErkJggg==);
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
}
