body .main_heading {
  margin-bottom: 50px;
  color: #001c28;
  font-weight: 700;
  font-size: 40px;
  font-family: Poppins;
}
.filter_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter_wrap p {
  margin: 0 15px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}
.filter_wrap .Cohort_label {
  margin-left: 56px;
}
body .select_box {
  width: 100%;
  max-width: 200px;
}
body .Numbers_applies {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  margin-top: 15px;
}
@media screen and (max-width: 1300px) {
  .filter_wrap .Cohort_label {
    margin-left: 20px;
  }
  body .main_heading {
    font-size: 32px;
    margin-bottom: 30px;
  }
  .filter_wrap p {
    margin: 0 10px;
    font-size: 12px;
  }
  body .select_box {
    max-width: 150px;
  }
}
