body .heding_5 {
  color: #001c28;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
body .heding_2 {
  color: #001c28;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 42px;
}
.upload_file_wrap {
  border-radius: 5px;
  border: 2px dashed #001c28;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
}
.upload_file_wrap p {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  min-width: 130px;
  line-height: normal;
  padding-left: 5px;
}
.upload_file_wrap .u_button {
  border-radius: 5px;
  background: #001c28;
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 9px;
}
.upload_file_wrap .u_button:hover {
  background: #001c28;
  color: #fff;
  opacity: 0.8;
}
.radio_label [class*="MuiTypography-root"] {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.6px; /* 218.571% */
  text-transform: capitalize;
}
