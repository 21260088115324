.transactionsDetail .heading {
  color: #001c28;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 62px;
}

.transactionsDetail .email,
.transactionsDetail .subHeading {
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  line-height: 15.6px;
}
.transactionsDetail .email {
  margin-bottom: 15px;
}

.transactionsDetail .transactionsId {
  color: #fff;
  font-family: Poppins;
  font-size: 34px;
  font-weight: 700;
  line-height: 40.8px;
  margin-right: 23px;
}
.transactionsDetail .emailAdress {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.transactionsDetail .avatarName {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 120%;
}

.transactionsDetail .gradientBackground {
  min-height: 200px;
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
  box-shadow: 0px 34px 64px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}
.transactionsDetail .infoWrap {
  margin-top: 32px;
}
.transactionsDetail .borderLeft {
  border-left: 1.5px solid rgb(254 254 254 / 29%);
  padding-left: 20px;
}
.transactionsDetail .ammoutWrap {
  border-radius: 5px;
  background-color: #fff;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.transactionsDetail .label {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.6px;
}
.transactionsDetail .amount {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.4px;
}
.transactionsDetail .buttonWrap button {
  border-radius: 5px;
  background-color: #001c28;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 10px 22px;
}
.transactionsDetail .buttonWrap .buttonRed {
  background-color: #c33f28;
  margin-left: 17.5px;
}
.OrgTransactions .datePicker > div {
  display: block;
}
.OrgTransactions .datePicker > div > div {
  margin-top: 0;
}
.OrgTransactions textarea {
  width: 100%;
}
body .fundHeading {
  color: #001c28;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
}
