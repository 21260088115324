.display_left {
  display: flex;
  justify-content: space-between;
}

.display_right {
  display: flex;
}
.table_wrap {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 9px 2px rgba(37, 10, 43, 0.05);
  padding: 42px;
}
.Job_Experience [class*="btn-flex"] {
  justify-content: flex-start;
  margin-top: 40px;
}
.Job_Experience [class*="MuiFormControlLabel-label"] {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Job_Experience [class*="Mui-error"] {
  margin-left: 0;
}
body .Job_Experience [class*="MuiAutocomplete-inputRoot"] {
  padding: 5px 8px;
}

@media screen and (max-width: 1360px) {
  .display_left {
    display: block;
  }
  .display_right {
    margin-top: 20px;
  }
}
