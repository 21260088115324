/* Student Card */
.studentProfileList {
  display: flex;
  align-content: center;
  background-color: #fff;
  overflow: hidden;
}
.rightSide {
  border-left: 1px solid #edeced;
}
.studentCardBx {
  border-radius: 0px;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 30px;
}

body .studentCardContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0px 10px;
  max-width: 220px;
  width: auto;
  height: auto;
  flex-direction: column;
  align-items: center;
}
.studentCardContent .id {
  border-radius: 3px;
  background: #ebebeb;
  max-width: 65.539px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 7px 0;
}
.studentCardContent .id span {
  display: inline-block;
  margin-top: 0;
  font-weight: bold;
}

.stuProfile {
  width: 155px !important;
  height: 155px !important;
  margin-bottom: 25px;
}

.stuName {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  font-family: poppins;
  text-transform: capitalize;
}

.stuName span span {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  display: block;
  margin-top: 5px;
}
.stuName span [class*="MuiLinearProgress-bar"] {
  margin-top: 0;
}
.stuName [class*="MuiLinearProgress-root"] {
  margin: 12px 0;
}

body .stuLastActive {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px;
  margin-bottom: 10px;
}

.stuLastActive b {
  font-weight: 700;
}

.stuViewDetailBtn {
  display: flex;
  position: relative;
  justify-content: center;
}

.stuViewDetailCheck {
  position: absolute;
  width: 100%;
  z-index: 99;
  opacity: 0;
}

.stuViewBtn {
  background-color: #000 !important;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 700 !important;
}

.gridItem {
  padding: 45px 40px;
}

.gridItem h3 {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 15px;
}

.gridItem span {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-right: 40px;
  display: inline-block;
}

.gridItem span:last-child {
  margin-right: 0;
}

.gridItem span span {
  font-weight: 700;
  margin-left: 10px;
}
.gridItem span span.exp {
  background-color: #d9d9d9;
  padding: 3px 10px;
  font-weight: 400;
  color: #001c28;
}

.archived {
  border-radius: 2px;
  opacity: 0.4000000059604645;
  background: #d9d9d9;
  padding: 5px 0;
  max-width: 82px;
  width: 100%;
  display: inline-block;
  text-align: center;
}
.active {
  border-radius: 2px;
  background: #00cf53;
  padding: 5px 16px;
  color: #feffff;
}
.due {
  border-radius: 2px;
  background: #c33f28;
  color: #fff;
  padding: 0 10px;
}
.paid {
  border-radius: 2px;
  background: #d9d9d9;
  padding: 0 10px;
}
.filter_flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.teaViewBtn {
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  background-color: #c33f28;

  font-weight: 700;
}
.filter_btn {
  margin-right: 20px;
}

.addStudent input {
  padding: 10px 15px;
}
.addStudent .locationFields [aria-haspopup="listbox"] {
  padding: 10px 15px;
}

.active_student select {
  border-radius: 5px;
  border: 2px solid rgb(0 28 40 / 12%);
  width: 142.394px;
  height: 34.69px;
  padding: 0 7px;
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fee_status {
  border-radius: 2px;
  background: #d9d9d9;
  padding: 5px 15px;
  text-transform: capitalize;
}

.filter_search {
  flex-wrap: wrap;
}

@media (max-width: 1650px) {
  .filter_search {
    margin-bottom: 35px;
  }
  .filter_search form {
    margin-bottom: 20px;
  }
}
@media (max-width: 1600px) {
  .gridItem span {
    margin-right: 25px;
    margin-bottom: 5px;
  }
}
@media (max-width: 1450px) {
  .rightSide {
    padding-left: 0 !important;
  }
  .studentCardContent img {
    height: 100px !important;
    width: 100px !important;
  }
  .stuName span {
    font-size: 16px;
  }
  .teaViewBtn {
    font-size: 12px;
    padding: 7px 14px;
  }
  .gridItem {
    padding: 18px 20px;
  }
}
@media (max-width: 1280px) {
  .studentCardContent img {
    height: 70px !important;
    width: 70px !important;
  }
}
