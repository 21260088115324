/* SECTION MODULE */

.popup_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1280px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}
.flexform {
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.flexformfield {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 10px;
}

.stepone {
  width: 33%;
  /* border-right: 1px solid; */
  padding-right: 20px;
  /* border-color: rgba(217, 217, 217, 1); */
}

.steptwo {
  width: 33%;
}
.margin {
  margin: 0px 30px 0px !important;
  color: #001c28;
  font-size: 14px;
}
.seperatemargin {
  margin: 10px 30px 50px !important;
  width: 75% !important;
}
.plusbtn {
  background-color: #c33f28 !important;
  position: relative !important;
  bottom: -9px !important;
  padding: 8px 20px !important;
  min-width: 0 !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  left: -10px !important;
}

.minusbtn {
  color: #004c6e !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  background-color: rgba(0, 76, 110, 0.19) !important;
  box-shadow: none !important;
  min-width: 0 !important;
  max-width: 34px;
  width: 34px;
  height: 28.5px;
  padding: 2px 10px !important;
  /* position: relative !important; */
  /* top: -40px !important; */
  /* left: -24px !important; */
}
.minusbtn:hover {
  background-color: rgba(0, 76, 110, 0.19);
}
.fieldflex {
  display: flex;
}
.fieldflexheading {
  font-size: 14px;
  color: #001c28;
}

.close_btn {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
  right: 30px;
  font-size: 0;
  border: 0;
  background: transparent;
  opacity: 1 !important;
  cursor: pointer;
}
.close_btn:before {
  cursor: pointer;
  content: "";
  background-image: url("../../../static/images/times.png");
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0;
  top: 0;
}

.courseDetailFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 20px 50px;
}
.courseDetailFlex .MuiBox-root {
  width: 50%;
  text-align: end;
}
.courseDetailFlexHeading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.courseDetailFlexHeading span,
.courseDetailFlexHeading h2 {
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 40px;
}
.courseDetailFlexHeading span {
  font-size: 14px;
  color: #001c28;
}
.courseDetailFlexHeading h2 {
  font-size: 30px;
  color: #001c28;
}
.coude {
  font-size: 16px;
  color: #001c28;
  font-weight: 400;
  overflow-wrap: anywhere;
}
.coufee {
  font-size: 27px;
  color: #001c28;
  font-weight: 700;
}
.couViewBtn {
  text-decoration: none !important;
  font-weight: 700 !important;
  color: #fff !important;
  border-radius: 4px;
  margin: 15px 0px 0 0 !important;
  padding: 15px 30px;
  background: #c33f28;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
}
.section_add {
  justify-content: end;
}
.courseDetailSecond {
  display: flex;
  align-items: end;
  margin-bottom: 40px;
  width: auto;
  margin-left: 50px;
}
.bold {
  margin-right: 10px;
  font-weight: 700;
  font-size: 14px;
  margin-left: 10px;
}
.courseDetailFlexContent {
  width: 80%;
}
.mrheading {
  margin-left: 30px;
}

@media (max-width: 1650px) {
  .mrheading {
    font-size: 18px;
  }
  .topHeading {
    font-size: 18px;
  }
  .couViewBtn {
    padding: 12px 16px;
    min-width: 133px;
    text-align: center;
  }
}
@media (max-width: 1450px) {
  .stepone {
    width: 50%;
    padding-right: 20px;
  }

  .steptwo {
    width: 50%;
  }
  body .stepthree .margin {
    margin-left: 0 !important;
    margin-right: 30px !important;
  }
  body .stepthree .mrheading {
    margin-left: 0;
  }
  body .stepthree .seperatemargin {
    margin-left: 0 !important;
    width: 82% !important;
  }
  body .stepthree {
    margin-top: 20px;
  }
}
