.job_cards_wrap .col .col_wrap {
  padding: 50px;
}
.job_cards_wrap .col .col_wrap h3 a {
  color: #001c28;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 40px;
  display: block;
  text-decoration: none;
}
.job_cards_wrap .col .col_wrap h4 {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  margin: 16px 0 0 0;
}
.job_cards_wrap .col .col_wrap .para {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.job_cards_wrap .col .col_wrap h6 {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 22px 0;
}
.job_cards_wrap .col .col_wrap h6 span {
  font-weight: 700;
}
.small_box .key {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.2px;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

body .small_5 .small_box {
  width: calc(100% / 4 - 2%);
  margin-right: 2%;
}

.small_box {
  border-radius: 5px;
  background: #f1f5f6;
  width: calc(100% / 3 - 1.4%);
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 39px;
  flex-wrap: wrap;
  padding: 10px;
}

.small_box:last-child {
  margin-right: 0;
}
.small_box .value {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  display: block;
  width: 100%;
  text-align: center;
}

body .job_cards_wrap .chip {
  border-radius: 26.875px;
  background: #043b53;
  color: #f8f8f9;
  font-family: Poppins;
  font-size: 12.78px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 15.336px */
  padding: 6px 14px;
  margin-top: 13px;
  display: inline-block;
  margin-right: 5px;
}
body .btn_wrapper {
  margin-top: 40px;
}
body .btn_wrapper button.btn {
  color: #fff;
  text-align: center;
  font-family: Poppins !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  width: 100%;
  max-width: 155px;
  padding: 13px 0;
}
body .btn_wrapper button.btn:hover {
  opacity: 0.8;
}
body .btn_wrapper button.cancle {
  background: #001c28;
  margin-right: 10px;
}
body .btn_wrapper button.update {
  background: #c33f28;
}
body .btn_wrapper button.update a {
  color: #fff;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}
.apply_job [class*="btn-flex"] {
  justify-content: center;
}
