.settingGeneralMain {
  padding: 105px 54px;
}
.settingGeneralMain .settingGeneralContainer .settingGeneralForm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 20px 35px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 17px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox:last-child {
  margin-bottom: 0px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox:first-child
  .settingGeneralFormFieldInput {
  width: calc(40% - 160px);
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldLabel {
  font-size: 14px;
  font-weight: 700;
  max-width: 160px;
  width: 100%;
  margin-top: 12px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldInput {
  width: calc(60% - 160px);
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldInput
  input {
  padding: 11px 14px;
  font-size: 14px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldTextArea {
  width: calc(60% - 65px);
  padding: 11px 14px;
  font-size: 14px;
  border-radius: 4px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldUpload {
  background: transparent;
  padding: 7px 14px;
  border-radius: 6px;
  border: 2px dotted rgba(78, 78, 78, 0.12);
  box-shadow: none;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins";
  position: relative;
  width: 153.421px;
  height: 43px;
}

.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .favicon {
  width: 67.501px;
  height: 43px;
}

.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldUpload
  .logo {
  width: 100%;
  height: 100%;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldUpload
  .favicon {
  width: 100%;
  max-width: 50px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralForm
  .settingGeneralFormFieldBox
  .settingGeneralFormFieldUpload::before {
  content: "";
  background: url("../../../static/images/upload.svg");
  width: 17px;
  height: 19px;
  position: absolute;
  right: -36px;
  background-size: 100%;
}
.settingGeneralMain .settingGeneralContainer .settingGeneralCheckBoxContent {
  padding: 37px 20px 0px;
  border-top: 1px solid #edeced;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContentTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 26px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContainer
  .sGCBoxContent {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContainer
  .sGCBoxContent
  label {
  width: 100%;
  height: 100%;
  margin: 0px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContainer
  .sGCBoxContent
  label
  span {
  padding: 0px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContainer
  .sGCBoxText
  h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 5px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralCheckBoxContent
  .settingGeneralCheckBoxContainer
  .sGCBoxText
  p {
  font-size: 14px;
}
.settingGeneralMain .settingGeneralContainer .settingGeneralBtn {
  padding: 38px 20px 0px;
}
.settingGeneralMain
  .settingGeneralContainer
  .settingGeneralBtn
  .settingGeneralBtnContent {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  text-transform: capitalize;
  padding: 13px 50px;
  border-radius: 5px;
  background: #071836;
  color: #fff;
  margin-right: 10px;
}
.settingAdminMain {
  padding: 17px 33px;
}
.settingAdminMain .settingAdminTableContainer {
  box-shadow: none;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableHead {
  background-color: #071836;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableHead
  .settingAdminTableRow {
  background-color: #071836;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableHead
  .settingAdminTableRow
  th {
  color: #fff;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 700;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  td
  p {
  font-size: 14px;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminTDAvator {
  display: flex;
  align-items: center;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminTDAvator
  div {
  width: 34px;
  height: 34px;
  margin-right: 17px;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminTDAvator
  p {
  font-size: 14px;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminActionBtn
  .btnRemove {
  width: 28px;
  height: 28px;
  max-width: 28px;
  min-width: 28px;
  background: #faf6ec;
  padding: 0px;
  margin-right: 10px;
  position: relative;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminActionBtn
  .btnRemove::before {
  content: "";
  background: url(../../../static/images/delet-trash.svg) no-repeat center /
    contain;
  position: absolute;
  width: 14px;
  height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminActionBtn
  .btnEdit {
  width: 28px;
  height: 28px;
  max-width: 28px;
  min-width: 28px;
  background: #faf6ec;
  padding: 0px;
  position: relative;
}
.settingAdminMain
  .settingAdminTableContainer
  .settingAdminTable
  .settingAdminTableBody
  .settingAdminActionBtn
  .btnEdit::before {
  content: "";
  background: url(../../../static/images/edit-pen.svg) no-repeat center /
    contain;
  position: absolute;
  width: 14px;
  height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.settingChangePasswordMain {
  padding: 39px 46px;
}
.settingChangePasswordMain .settingCPContainer .settingCPTitle {
  margin-bottom: 26px;
}
.settingChangePasswordMain .settingCPContainer .settingCPTitle h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}
.settingChangePasswordMain .settingCPContainer .settingCPTitle p {
  font-size: 14px;
}
.settingChangePasswordMain .settingCPContainer .settingCPForm .passField {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.settingChangePasswordMain
  .settingCPContainer
  .settingCPForm
  .passField:last-child {
  margin-bottom: 0px;
}
.settingChangePasswordMain
  .settingCPContainer
  .settingCPForm
  .passField
  .passFieldLabel {
  font-size: 14px;
  font-weight: 700;
  max-width: 200px;
  width: 100%;
}
.settingChangePasswordMain
  .settingCPContainer
  .settingCPForm
  .passField
  .passFieldInput {
  width: calc(100% - 200px);
}
.settingChangePasswordMain
  .settingCPContainer
  .settingCPForm
  .passField
  .passFieldInput
  input {
  padding: 13px 14px;
}
.settingChangePasswordMain .settingCPContainer .settingCPBtn {
  margin-top: 94px;
  padding-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: end;
}
.settingChangePasswordMain .settingCPContainer .settingCPBtn button {
  padding: 13px 50px;
  background: linear-gradient(91.35deg, #a3915e 1.15%, #d6c38f 66.97%);
  color: #fff;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  text-transform: capitalize;
}
