.attendance_wrap {
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 9px 0px rgba(37, 10, 43, 0.05);
}

.filter_container .item {
  display: flex;
  align-items: center;
}
.filter_container .item_1 .select_box [class*="MuiSelect-select"],
.filter_container .item_2 .select_box [class*="MuiSelect-select"],
.filter_container .item_3 .select_box [class*="MuiSelect-select"] {
  width: 95px;
}
.filter_container .item_1,
.filter_container .item_2,
.filter_container .item_3 {
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 25px;
}
.filter_container .item_4,
.filter_container .item_5 {
  width: 330px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 25px;
}
.filter_container .item_5 {
  padding-right: 0;
  width: 372px;
}

.filter_container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
}
.filter_container_Teacher {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin-bottom: 25px;
  width: 100%;
  max-width: 292px;
}
.filter_container_Teacher .select_box {
  width: 100%;
}

.filter_container_Teacher .item_1 {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 292px;
}
.filter_container_Teacher p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  min-width: 75px;
}
.filter_container p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  min-width: 75px;
}
.filter_wrap .select_box {
  margin: 0 15px;
}

.filter_container [class*="react-datepicker__input-container"] {
  margin-top: 0;
}
