.addWorkshp .formRow {
  margin-bottom: 25px;
}

.addWorkshp .formRow [class="react-datepicker__input-container"] {
  margin-top: 0;
}

.addWorkshp .formRow [class="react-datepicker-wrapper"] {
  width: 100%;
}

.addWorkshp .formRow div[class*="MuiSelect-select"] {
  padding: 10px 15px;
}

body .outerData {
  display: block;
}

body .outerData .outerDatalist {
  display: flex;
  align-items: center;
  background-color: rgb(225, 225, 225);
}

.outerData .innerData li {
  display: flex;
}

.outerData .innerData {
  padding-left: 0;
}

.phaseCohortList {
  padding-left: 20px;
}

.phaseCohortList li {
  list-style-type: none;
}

.phaseCohortList > li {
  list-style-type: none;
}

.phaseCohortList > li > label {
  width: 100%;
}

.phaseCohortList li ul {
  padding-left: 25px;
}

/* workshop detail css */

.workShopDetail .heading {
  color: #001c28;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  text-transform: capitalize;
}
.workShopDetail .workShopDetailHeader {
  border-bottom: 1px solid #edeced;
  padding: 0px 0 25px 0;
  align-items: flex-start;
}
.workShopDetail .workShopDetailHeader .workShopDetailHeaderleft {
  padding-left: 45px;
}

.workShopDetail .workShopInfo .subHeading {
  color: #001c28;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.workShopDetail .workShopInfo {
  padding: 30px 0px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
}

.workShopDetail .workShopInfo .workShopInfoSubHeading {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-bottom: 5px;
}

.workShopDetail .downloadBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.workShopDetail .infoWrap {
  margin-top: 26px;
  padding: 0 120px 0 45px;
  align-items: flex-start;
}

.workShopDetail .workShopInfoValue {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  max-width: 250px;
}

.workShopDetail .workShopbtnsWrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 45px;
}

body .workShopDetail .workShopbtnsWrapper button {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 13px 25px;
}

body .workShopDetail .workShopbtnsWrapper .EditButton {
  border-radius: 5px;
  background: #004c6e;
  color: #ffffff;
}

body .workShopDetail .workShopbtnsWrapper .AttendanceButton {
  margin-left: 15px;
  border-radius: 5px;
  background: #c33f28;
  color: #ffffff;
}
.workShopDetail .workShopTrainer {
  display: flex;
  align-items: center;
}

body .upload_teacher_image div {
  display: flex;
  align-items: center;
}
body .upload_teacher_image [class*="MuiButtonBase-root"] {
  background-color: #004c6e;
  padding: 2px 0;
  font-weight: 700;
  width: 100%;
  max-width: 54px;
  min-width: auto;
}
body .upload_teacher_image img {
  width: 43px;
  height: 43px;
  border-radius: 100%;
  margin-right: 12px;
}

.addWorkshopBtn a{
  padding: 13px 17px!important;
}
body .WORKSHOP_PAGINATION [data-column-id="3"] > div,
body .WORKSHOP_PAGINATION [data-column-id="4"] > div{
  white-space: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
}

body .select_custom{
  width: 100%;
  height: 43px;
  padding: 10px 14px;
  border-radius: 5px;
  border-color: #00000030;
  color: #8F979B;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px

}


@media(max-width:1650px){
  .workShopDetail .infoWrap {
    padding: 0 20px 0 20px;
  }
}
@media(max-width:1550px){
  .workShopDetail .workShopInfoValue {
    max-width: 350px;
  }
  .borderLeft{
    margin-left: 50px;
    margin-bottom: 20px;
  }
  .workShopDetail .infoWrap {
    justify-content: flex-start;
  }
}
@media(max-width:1280px){
  .borderLeft{
    margin-left: 30px;
  }
  .borderLeft{
    margin-left: 30px;
  }
  .workShopDetail .workShopInfoValue {
    max-width: 300px;
  }
  body .workShopDetail .workShopbtnsWrapper button {
    padding: 12px 14px;
    min-width: 164px;
    text-align: center;
  }
}
@media(max-width:1100px){
  .borderLeft{
    margin-left: 20px;
    }
    .workShopDetail .workShopInfoValue {
      max-width: 300px;
    }
  
}