.display_left {
  display: flex;
  justify-content: space-between;
}
.display_right {
  display: flex;
}
.table_wrap {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 9px 2px rgba(37, 10, 43, 0.05);
  padding: 42px;
}
@media screen and (max-width: 1700px) {
  .display_left {
    display: block;
  }
  .display_right {
    margin-top: 20px;
  }
}
