/* ============ poppins fonts ============ */

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Black.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Black.woff") format("woff"),
    url("../fonts/poppins/Poppins-Black.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Bold.woff") format("woff"),
    url("../fonts/poppins/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Medium.woff") format("woff"),
    url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-Regular.woff") format("woff"),
    url("../fonts/poppins/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
.email_broadcast .react-datepicker__input-container{
  margin-top: 0;
}
::placeholder {
  font-family: "Poppins";
}

body {
  font-family: "Poppins";
}
 .elipsis{ 
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        max-width: 180px
 }
.mainContainer {
  min-height: 80vh;
}
body .MuiChip-label,
body .MuiList-root li,
body .css-sws92j-JoyTextarea-textarea {
  font-family: "Poppins" !important;
}
body .MuiInputBase-input {
  padding: 12.2px 14px;
}

.support-tickets-disc > * {
  display: none; 
}

.support-tickets-disc > *:first-child {
  display: block; 
  font-weight: 400;
  font-size: 13px;
}
.support-tickets-disc  * {
  font-weight: 400;
  font-size: 13px;
}
/* =========== transition css =========== */

.transition-screen-enter {
  opacity: 0;
  transform: translateX(100px);
}
.transition-screen-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s, transform 1s;
}
.transition-screen-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s, transform 1s;
}
.transition-screen-exit-active {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 1s, transform 1s;
}

body {
  font-family: "Poppins" !important;
  overflow-x: hidden;
}
button,
p,
textarea {
  font-family: "Poppins" !important;
}
.ck.ck-balloon-panel.ck-powered-by-balloon[class*="position_border"] {
  opacity: 0;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 171px !important;
}
.container {
  width: 97%;
  margin: 0 auto;
  margin-top: 80px;
  max-width: 100%;
}

/* HEADER */
body.for-bg {
  background-image: url("../images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
body.for-bg .container {
  margin-top: 0;
}
body.for-bg .container main {
  margin: 0;
  margin: auto;
}
body.for-bg .container main > .MuiBox-root {
  margin-top: 0 !important;
}
body.for-bg div#root {
  height: 98.5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.side-bar .main-logo {
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
}
.side-bar .MuiPaper-root {
  background: linear-gradient(180deg, #074e6c 9.25%, #001c28 100%);
  border: none;
}
.dashboard-main header {
  background: #fff;
  border-left: 1px solid #ebebeb;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
}
.dashboard-main header img.togglebutton {
  position: absolute;
  z-index: +9999;
  top: 10px;
  left: 20px;
  cursor: pointer;
}
.dashboard-main header .top_header_inner {
  margin-left: 90px;
}
.dashboard-main header .top_header_inner a {
  color: #001c28;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
}
.signOut {
  color: #001c28;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.signOut_tooltip {
  background-color: #fff;
  color: #000;
  display: inline-block;
  padding: 10px 10px;
  font-size: 15px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.signOut span {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides the overflowed content */
  text-overflow: ellipsis; /* Adds the ellipsis (...) when text overflows */
  max-width: 115px;
}
.header-search .MuiInputBase-root {
  flex-direction: row-reverse;
  border: 2px solid rgb(0 28 40 / 12%);
  border-radius: 5px;
}
.header-search .MuiInputBase-root svg {
  right: 10px;
  position: relative;
}
.header-search .MuiInputBase-root input::placeholder {
  font-size: 13px;
  color: #8f979b;
  font-weight: 700;
}
.header-search .MuiInputBase-root fieldset {
  border: none;
}
.loginUser {
  padding-right: 20px;
}
body .listItemHide {
  display: none;
}
body [sortdirection="desc"],
body [sortdirection="asc"] {
  color: #fff;
  opacity: 1;
  margin-left: 15px;
  font-size: 0;
}
body [sortdirection="desc"]::before,
body [sortdirection="asc"]::before {
  color: #fff;
  opacity: 1;
  margin-left: auto;
  content: url('data:image/svg+xml; utf8, <svg width="7" height="16" viewBox="0 0 7 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.39844 3.78027C6.78516 3.78027 6.97852 3.3291 6.69922 3.0498L3.94922 0.299805C3.77734 0.12793 3.49805 0.12793 3.32617 0.299805L0.576172 3.0498C0.296875 3.3291 0.490234 3.78027 0.876953 3.78027H6.39844ZM0.876953 11.3428C0.490234 11.3428 0.296875 11.8154 0.576172 12.0947L3.32617 14.8447C3.49805 15.0166 3.77734 15.0166 3.94922 14.8447L6.69922 12.0947C6.97852 11.8154 6.78516 11.3428 6.39844 11.3428H0.876953Z" fill="white"/></svg>');
}

.sc-dmXVtG::-webkit-scrollbar {
  height: 10px;
}

.sc-dmXVtG::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sc-dmXVtG::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
}

/* MENU */
.sidebar-menu ul.menu-wrap {
  list-style-type: none;
  margin: 60px 40px 0;
  padding: 0 0 0 0px;
}
.sidebar-menu ul.menu-wrap li a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-left: 55px;
}
.sidebar-menu ul.menu-wrap li {
  padding: 12px 0;
  margin-bottom: 15px;
  position: relative;
}
.sidebar-menu ul.menu-wrap li.dashboard-icon:before {
  content: "";
  background-image: url("../images/dashboard.png");
  width: 19px;
  height: 19px;
  position: absolute;
  left: 20px;
  top: 13px;
}
.sidebar-menu ul.menu-wrap li.assign-icon:before {
  content: "";
  background-image: url("../images/assign-icon.png");
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 13px;
}
.sidebar-menu ul.menu-wrap li.assesment_icon:before {
  content: "";
  background-image: url("../images/assesment_icon.png");
  width: 16.48px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 13px;
}
.sidebar-menu ul.menu-wrap li .users-item {
  display: inline-block;
  width: 100%;
  padding: 12px 0;
}
.sidebar-menu ul.menu-wrap li.has-sub-menu {
  padding: 0;
}
.sidebar-menu ul.menu-wrap li.active .users-item {
  background: #ffffff;
  border-radius: 9px;
  position: relative;
}
.sidebar-menu ul.menu-wrap li.user-icon:before {
  content: "";
  background-image: url("../images/users-white.png");
  width: 25px;
  height: 25px;
  position: absolute;
  left: 20px;
  top: 8px;
  z-index: +9;
}
.sidebar-menu ul.menu-wrap li.doc-icon .sub-menu a {
  margin-left: 15px;
}
.sidebar-menu ul.menu-wrap li.doc-icon .sub-menu li{
  margin-bottom: 15px;
}
.sidebar-menu ul.menu-wrap li.doc-icon:before {
  content: "";
  background-image: url("../images/doc-icon.png");
  width: 25px;
  height: 25px;
  position: absolute;
  left: 22px;
  top: 12px;
  z-index: +9;
  background-size: 20px;
  background-repeat: no-repeat;
}

.sidebar-menu ul.menu-wrap li.user-icon.active {
  background: none;
}

.sidebar-menu ul.menu-wrap li.finances-icon:before {
  content: "";
  background-image: url("../images/finances-icon.svg");
  width: 25px;
  height: 25px;
  position: absolute;
  left: 20px;
  top: 11px;
  z-index: +9;
}

.sidebar-menu ul.menu-wrap li.finances-icon.active {
  background: none;
}

.sidebar-menu ul.menu-wrap li.finances-icon.active .users-item {
  padding: 12px 99px 12px 0px;
}

.sidebar-menu ul.menu-wrap li.active ul.sub-menu {
  background-color: rgba(255, 255, 255, 0.1);
  list-style-type: none;
  padding: 0;
}
.sidebar-menu ul.sub-menu li.sub_active a {
  color: #c33f28 !important;
}

.sidebar-menu ul.menu-wrap li.active ul.sub-menu li a {
  color: #fff;
}

.sidebar-menu ul.menu-wrap li.setting-icon:before {
  content: "";
  background-image: url("../images/setting.png");
  width: 21px;
  height: 21px;
  position: absolute;
  left: 20px;
  top: 13px;
}

.sidebar-menu ul.menu-wrap li.workshop-icon:before {
  content: "";
  background-image: url("../images/workshopicon.png");
  width: 21px;
  height: 21px;
  position: absolute;
  left: 20px;
  top: 13px;
}
.sidebar-menu ul.menu-wrap li.atten-icon:before {
  content: "";
  background-image: url("../images/atten-icon.png");
  width: 27px;
  height: 28px;
  position: absolute;
  left: 15px;
  top: 13px;
}
.sidebar-menu ul.menu-wrap li.reports_icon:before {
  content: "";
  background-image: url("../images/reports_icon.png");
  width: 25px;
  height: 25px;
  position: absolute;
  left: 15px;
  top: 12px;
  background-repeat: no-repeat;
}
.sidebar-menu ul.menu-wrap li.active:before {
  filter: brightness(0) saturate(100%) invert(26%) sepia(40%) saturate(3505%)
    hue-rotate(348deg) brightness(98%) contrast(85%);
  background-repeat: no-repeat;
}

.sidebar-menu ul.menu-wrap li.course-icon:before {
  content: "";
  background-image: url("../images/courses.png");
  width: 25px;
  height: 25px;
  position: absolute;
  left: 20px;
  top: 13px;
}
.sidebar-menu ul.menu-wrap li.cohort-icon:before {
  content: "";
  background-image: url("../images/cohort_icon.png");
  width: 20px;
  height: 25px;
  position: absolute;
  left: 20px;
  top: 13px;
  background-repeat: no-repeat;
}
.sidebar-menu ul.menu-wrap li.email-icon:before {
  content: "";
  background-image: url("../images/email_icon.png");
  width: 28px;
  height: 28px;
  position: absolute;
  left: 17px;
  top: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.sidebar-menu ul.menu-wrap li.all-section-icon:before {
  content: "";
  background-image: url("../images/all-section-icon.png");
  width: 25px;
  height: 17px;
  position: absolute;
  left: 20px;
  top: 15px;
  background-repeat: no-repeat;
}

.sidebar-menu ul.menu-wrap li:last-child {
  margin-bottom: 0;
}
.sidebar-menu ul.menu-wrap li.active a {
  color: #c33f28;
}
.sidebar-menu ul.menu-wrap li.active {
  background: #ffffff;
  border-radius: 9px;
}

/* CARDS */
.card_hover:hover {
  background: linear-gradient(96.26deg, #001c28 -34.09%, #074e6c 110.58%);
  box-shadow: 0px 24px 44px rgba(37, 10, 43, 0.04);
  border-radius: 10px;
  transition: 1s ease all;
}
.card_hover:hover p {
  color: #fff;
  transition: 0.5s;
}
.card_hover:hover h3 {
  color: #fff;
  transition: 0.5s;
}

/* STUDENTS ONLINE CARD */
body .student_section {
  width: 50%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 24px 44px rgba(37, 10, 43, 0.04);
  padding: 30px;
  margin-top: 40px;
  margin-right: auto;
  display: block;
}
.student_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.student_row h3 {
  font-size: 22px;
  font-weight: 700;
  color: #001c28;
}
.student_row .MuiTabs-root button {
  font-size: 12px;
  color: #001c28;
  background: #ececec;
  border-radius: 42px;
  margin-right: 20px;
  padding: 10px 20px;
  min-height: auto;
}
.student_row .MuiTabs-root button:last-child {
  margin-right: 0;
}
.student_row .MuiTabs-root button.Mui-selected {
  background: #c33f28;
  border-radius: 42px;
  color: #fff;
}
.MuiTabs-indicator {
  display: none;
}
.pagination_wrap_container {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
body .Mui-error {
  margin-left: 0;
}
.pagination_wrap ul li button {
  min-width: 26px;
  height: 26px;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  background-color: #e2eaec99;
  border: 0;
  color: #001c28;
}
.global_font_style .rdt_TableRow div {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
body .hNajNr {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.pagination_wrap ul li button[class*="Mui-selecte"] {
  background-color: #001c28;
  color: #fff;
}
.pagination_wrap_container .count {
  color: #001c28;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px;
}
/* SETTINGS */

.settings h1 {
  color: #001c28;
  font-size: 40px;
  font-weight: 700;
}
.settings .page-container .page-container {
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}
.settings .table-container {
  background: #fff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.08);
}
.settings .table-container .MuiTabs-scroller {
  padding: 15px;
}
.settings .table-container .MuiTabs-scroller button {
  background: rgba(241, 245, 246, 1);
  padding: 20px 33px;
  color: rgba(0, 28, 40, 1);
  margin-right: 10px;
  font-size: 16px;
  text-transform: capitalize;
}
/* .container.settings .MuiTabs-flexContainer {
  justify-content: center;
} */
.settings .table-container .MuiTabs-scroller button:last-child {
  margin-right: 0;
}
.settings .table-container .MuiTabs-scroller button.Mui-selected {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(199, 25, 3, 1);
  font-weight: 700;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
}
.table-phases .rdt_TableHeadRow {
  background: linear-gradient(96.26deg, #001c28 -34.09%, #074e6c 110.58%);
  border-radius: 5px;
}
/* .table-phases .rdt_TableHeadRow .rdt_TableCol:first-child,
.table-phases .rdt_TableHeadRow .rdt_TableCell:first-child {
  max-width: 100%;
} */
/* body .ikexHN,
body .rPOCv {
  max-width: 300px;
} */
body .table-phases .emBIby,
body .table-phases .cRzJdb,
body .table-phases .cTRXdr {
  color: #fff;
  opacity: 1;
}
/* .table-phases .rdt_TableBody .rdt_TableCol:first-child,
.table-phases .rdt_TableBody .rdt_TableCell:first-child {
  max-width: 100%;
} */
.table-phases
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable
  .font-s-13 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  padding-right: 15px;
}
.table-phases .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable div,
.table-phases .rdt_TableHeadRow .rdt_TableCell .rdt_TableCol_Sortable div {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
}
.table-phases {
  /* margin: 0 30px; */
  padding-bottom: 100px;
}
.settings .btn-wrapper {
  text-align: end;
  padding: 0 0 50px 0;
  margin-right: 50px;
}
.settings .btn-wrapper a {
  padding: 15px 40px 15px;
  background: #c33f28;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
/* .table-phases .sc-feUZmu {
  overflow: hidden;
} */
.table-phases .rdt_TableBody button {
  position: relative;
  font-size: 0;
  padding: 0;
  display: block;
  min-width: 22px;
  max-height: 0 5px;
}

.table-phases .rdt_TableBody button.delete:before {
  content: "";
  position: absolute;
  background-image: url("../images/delete.png");
  width: 22px;
  height: 22px;
  left: 5px;
  top: -10px;
}

.table-phases .rdt_TableBody button.edit:before {
  content: "";
  position: absolute;
  background-image: url("../images/edit.png");
  width: 22px;
  height: 22px;
  left: 17px;
  top: -10px;
}
.student-resume .rdt_TableBody button.edit:before {
  left: 11px;
}

.table-phases .rdt_TableBody button.view:before {
  content: "";
  position: absolute;
  background-image: url("../images/view.png");
  width: 22px;
  height: 22px;
  /* left: 0; */
  top: -10px;
}

.table-phases .rdt_TableBody button.view.resume:before {
  content: "";
  position: absolute;
  background-image: url("../images/resume_d.png");
  width: 22px;
  height: 22px;
  left: 16px;
  top: -10px;
}
.settings .tab {
  border: none;
}
#simple-tabpanel-3 div#cell-4-1,
#simple-tabpanel-3 div#cell-3-1 {
  visibility: hidden;
}
#simple-tabpanel-3 div#cell-1-1 {
  position: relative;
}
#simple-tabpanel-3 div#cell-1-1:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 15%;
  background-image: url("../images/RedFlag.png");
}
.changepassword_mm p.MuiTypography-root {
  color: #001c28;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  margin-bottom: 20px;
}
.changepassword_mm h1 {
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins";
  margin: 20px 0;
}
.changepassword_mm form .MuiBox-root.css-0 {
  display: flex;
  align-items: center;
  position: relative;
}
.changepassword_mm form .MuiBox-root.css-0 label {
  width: 20%;
  font-weight: 700;
  font-family: "Poppins";
  color: #001c28;
}
.changepassword_mm form .MuiFormControl-root {
  width: 35%;
}
.changepassword_mm form .MuiBox-root.css-0 button.btn-blck:hover {
  background-color: black !important;
}
.changepassword_mm form .MuiBox-root.css-0 button.btn-red:hover {
  background-color: #c33f28 !important;
}
.changepassword_mm form .MuiBox-root.css-0 button {
  padding: 10px 50px;
  margin-right: 10px;
  margin-top: 200px;
  margin-bottom: 50px;
  text-transform: capitalize;
}
.changepassword_mm form .MuiBox-root.css-0:last-child:before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #edeced;
  width: 100%;
}
.changepassword_mm p.MuiTypography-root span {
  font-weight: 700;
}

/* POPUPS */

.popup .MuiGrid-root.MuiGrid-container {
  flex-direction: column;
  width: 100%;
}
.popup .MuiGrid-root.MuiGrid-item {
  max-width: 100%;
  width: 100%;
}
label.MuiFormLabel-root {
  color: #001c28;
  font-weight: 700;
  font-size: 14px;
  /* margin-bottom: 10px; */
  font-family: "Poppins";
}
.Add_Section label.MuiFormLabel-root {
  margin-bottom: 5px;
  transform: inherit;
}
label.is_admin_label.MuiFormLabel-root {
  transform-origin: inherit;
}
.popup .MuiGrid-root.MuiGrid-item input::placeholder {
  color: #001c28;
}
.popup .btn {
  margin: 0 10px 0px 0px;
  text-transform: capitalize;
  padding: 10px 40px;
  font-weight: 700;
}
body .btn_save {
  margin: 0 10px 0px 0px;
  text-transform: capitalize;
  padding: 10px 40px;
  font-weight: 700;
  border-radius: 5px;
  background: #c33f28;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

body .btn_save:hover {
  background: #c33f28;
  color: #fff;
}

.btn-flex {
  display: flex;
  justify-content: end;
}
.popup .btn.save {
  background: #c33f28;
}
.popup .btn.cancel {
  background: #001c28;
}

.popup .close_btn {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
  right: 30px;
  font-size: 0;
  border: 0;
  background: transparent;
  opacity: 1 !important;
  cursor: pointer;
  z-index: 999;
}
.skill .close_btn:before,
.popup .close_btn:before {
  cursor: pointer;
  content: "";
  background-image: url("../images/times.png");
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0;
  top: 0;
}

.role_swith_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.role_swith_img img {
  position: absolute;
}
.role_swith_img svg {
  opacity: 0;
}
.sectionAdd .role_swith_img svg {
  opacity: 1;
}

/* DATEPICKER */

.react-datepicker__input-container {
  margin-top: 25px;
  padding: 0 30px 0 0px;
}
.react-datepicker__input-container input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 11px 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.react-datepicker__month-container,
.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__navigation--next {
  right: 2px !important;
}

.action_btn .btn.save {
  background: #c33f28;
  margin: 0 10px 0px 0px;
  text-transform: capitalize;
  padding: 10px 40px;
  font-weight: 700;
  color: #fff;
}
.action_btn .btn.cancel {
  background: #001c28;
  margin: 0 10px 0px 0px;
  text-transform: capitalize;
  padding: 10px 40px;
  font-weight: 700;
  color: #fff;
}

/* USER MODULE */
.course-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-content h1.main-heading {
  font-size: 40px;
  color: #001c28;
}

.course-content .btn-wrapper a {
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  /* margin: 0 10px 0 0; */
  padding: 15px 30px;
  background: #c33f28;
  text-transform: capitalize;
}
.global-user h1 {
  font-size: 40px;
  font-weight: 700;
  font-family: "Poppins";
  color: #001c28;
}

.global-user .page-container {
  background: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin-top: 20px;
}
.global-user .btn-flex {
  justify-content: flex-start;
}
.active-tea {
  margin-left: 20px !important;
}
.active-tea .MuiInputBase-root {
  height: 56px;
  width: 88%;
}
.exp-width .MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.upload-image {
  position: relative;
}
.upload-image label {
  background-color: transparent;
  color: #000;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-family: "Poppins";
  text-transform: capitalize;
  border: none;
  box-shadow: none;
  font-size: 20px;
  font-family: "Poppins";
  position: absolute;
  width: 100%;
  height: 100%;
}
.profile_image_upload.isuploaded_image label {
  transition: all 1s ease;
  bottom: -66%;
  z-index: 1;
}
.profile_image_upload.isuploaded_image:hover label {
  bottom: 0;
  z-index: 1;
}

/* .upload-image:before {
  content: "";
  position: absolute;
  width: 100%;
  border: 2px dashed #8f979b;
  height: 100%;
  right: 30px;
  top: -55px;
  border-radius: 100%;
  z-index: 1;
} */

.upload-image label:hover {
  background-color: transparent;
  box-shadow: none;
}

.upload-image p {
  text-align: center;
}
.feeflex {
  display: flex;
  flex-direction: row-reverse !important;
  padding: 0 !important;
}
.feeflex .sho .MuiInputBase-root {
  width: 100%;
}
.feeflex .sho {
  width: 100%;
}
.feeflex .MuiInputBase-root {
  width: 40%;
}
.global-user .feeflex .MuiInputBase-root {
  width: 51%;
}
/* .feeflex .sho fieldset {
  border-right: none;
} */
div#mui-component-select-currency_id {
  font-size: 16px;
}
.upload-image img {
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  top: 0;
}
body .profile_image_upload {
  overflow: hidden;
  border: 2px dashed #8f979b;
  border-radius: 100%;
  width: 100%;
  height: 200px;
  max-width: 200px;
  position: relative;
}
.table-phases.enroll-student .rdt_TableBody button.edit:before {
  display: none;
}
.table-phases.enroll-student .rdt_TableBody button.delete:before {
  left: -6px;
}
.table-phases.enroll-student .rdt_TableBody button.view:before {
  left: 0;
}
.feeflex p {
  position: absolute;
  bottom: -22px;
  left: 0;
}

.feeflex {
  position: relative;
}
.assignment_form_wrapper .assignment_form_wrapper_section {
  padding: 10px;
}

.list_students {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 10px;
}
span.course_list {
  cursor: pointer;
}
form.gernel_Settings .btn-flex {
  display: flex;
  justify-content: flex-start;
  margin-left: 22px;
}
.dashboard-main.css-ttn1xo {
  width: 80%;
}
.dashboard-main.css-cdyy6c {
  width: 100%;
}
/* .rdt_TableCell div {
  overflow: visible !important;
} */
.table-phases .attendence_edit_btn button.edit:before {
  left: 0;
  top: 0;
}
.attendanceflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.attendance-form-flex {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.attendance-form-flex span.bold {
  color: #000;
  font-weight: 700;
}
.attendance-form-flex span.bolds {
  color: #000;
  font-weight: 500;
}
.attendance-form-edit {
  background: linear-gradient(96.26deg, #001c28 -34.09%, #074e6c 110.58%);
  border-radius: 5px;
  padding: 10px;
}
.attendance-form-edit h6 {
  color: #fff;
  font-size: 16px;
}
.transaction_module .react-datepicker__input-container {
  margin: 0;
}
.attendance-form-edit {
  position: sticky;
  top: 0;
  z-index: 1;
}
.attendance-form-edit-reason {
  position: sticky;
  top: 0;
  z-index: 1;
}
em {
  font-style: inherit;
  color: #a9a9ac;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  opacity: 0.6;
}
.shift table {
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0px 0px 30px -8px #888888;
  background-color: #fff;
  z-index: +99;
}
body .css-ja5taz-MuiTooltip-tooltip {
  background-color: inherit;
}

.shift {
  cursor: pointer;
}

.shift table tbody tr td:first-child {
  background-color: rgba(242, 242, 242, 1);
  padding: 15px;
}
.shift table tbody tr td:first-child {
  font-weight: 700;
}
.shift table tbody tr {
  position: relative;
}
.shift table tbody tr th {
  color: #001c28;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: #f2f2f2;
  padding: 5px 5px;
}
.shift table tbody tr td {
  color: #001c28;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 5px;
}

.shift table tbody tr:before {
  content: "";
  position: absolute;
  bottom: -1px;
  width: 100%;
  left: 2px;
  height: 1px;
  background-color: #edeced;
}
/* body .section-table {
  overflow: visible;
} */
.select-menu div {
  padding: 10px 32px 10px 32px;
}

body .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
body .MuiOutlinedInput-input {
  font-family: Poppins !important;
}
body .MuiTextarea-root {
  border-color: rgba(0, 0, 0, 0.23);
  background-color: transparent;
}
body .MuiTextarea-root,
.react-datepicker__input-container input,
body .MuiOutlinedInput-input {
  font-family: Poppins !important;
  font-size: 13px;
  color: #8f979b;
}

body .MuiSelect-select {
  padding: 10px 14px;
}
.poppins-font label {
  font-weight: 400;
  color: #a8b1b6;
  font-size: 16px;
}
.for-errors p.Mui-error {
  position: absolute;
  bottom: -22px;
}

.for-errors {
  position: relative;
}
/* .TabelOverflow #row-2 #cell-5-2 {
  overflow-x: scroll;
} */
/*
.TabelOverflow #row-2 #cell-5-2::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 5px;
}
.TabelOverflow #row-2 #cell-5-2::-webkit-scrollbar-track {
  background: #F5F5F5;
}
.TabelOverflow #row-2 #cell-5-2 {
  scrollbar-color: #666 #F5F5F5;
}
.TabelOverflow #row-2 #cell-5-2 {
  scrollbar-color: #666 #F5F5F5;
}*/

body .MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

body .MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.select_field_with_image .MuiSelect-select {
  display: flex;
  align-items: center;
}
body .dataCard .card_img {
  align-items: center;
}
body .dataCard:last-child {
  margin-right: 0;
}

[role="table"] [role="columnheader"] span {
  color: #fff;
  opacity: 1;
  pointer-events: none;
}
body .currncy_ammount:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}
body .currncy_ammount {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}
body
  .currncy_ammount
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
body .currncy_ammount .MuiSelect-select {
  padding: 8.9px 14px;
}
body .currncy_ammount .SelectOptionsApi fieldset {
  border: 0;
}
body .currncy_ammount .registration_fee fieldset {
  border: 0;
}
body .currncy_ammount .registration_fee .MuiInputBase-root {
  padding-left: 0;
  padding-right: 0;
}
body .currncy_ammount .registration_fee .MuiInputBase-root input {
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
}
.react-date-picker {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  height: 43px;
}
body .react-date-picker__wrapper {
  border: 0;
}
body .bg_field .react-date-picker__wrapper {
  background-color: #f4f4f4;
}
body .react-date-picker__inputGroup {
  padding-left: 10px;
}
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__input {
  padding: 0 1px !important;
  font-family: Poppins !important;
  font-size: 13px !important;
  color: #8f979b !important;
}
.attendence_filter .react-datepicker__input-container {
  margin-top: 0;
}
body .font-s-13 {
  color: #001c28;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px;
}
body .font-s-22 {
  color: #001c28;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.4px;
}
.dash_filter_active {
  position: sticky;
  left: 0;
  background-color: #022b3d;
  z-index: 1;
  border-radius: 5px;
}

.attendence_filter .rdt_TableRow > div:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}
.attendence_filter .rdt_TableHead .rdt_TableHeadRow {
  background: #064864;
}
body .react-datepicker-popper {
  z-index: 2;
}

.attendence_filter .rdt_TableHead .rdt_TableHeadRow > div:first-child {
  position: sticky;
  left: 0;
  background-color: #064864;
  z-index: 1;
  border-radius: 5px;
}
.attendence_filter .rdt_TableHead .rdt_TableHeadRow > div:last-child {
  position: sticky;
  right: 0;
  background-color: #064864;
  z-index: 1;
  border-radius: 5px;
  justify-content: center;
}
.attendence_filter .rdt_TableRow > div:last-child {
  position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 1;
  justify-content: center;
}

.date_range_filter .react-datepicker__input-container {
  margin-top: 0;
}
.date_range_filter .react-datepicker__input-container input {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}
body .date_range_filter h6 {
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}
body .date_range_filter .range_slider {
  height: 10px;
  color: rgba(0, 28, 40, 1);
  margin-left: 10px;
}

body .students_reports .rdt_Table .rdt_TableRow div {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
}
.upload_doc {
  width: 100%;
  border: 2px dotted rgb(0 28 40 / 12%);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  align-items: center;
  max-width: 250px;
  margin-right: 15px;
}
.upload_doc span {
  font-size: 14px;
  color: rgba(0, 28, 40, 1);
  display: inline-block;
  width: 114px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.upload_doc .upload_btn {
  background: rgba(0, 28, 40, 1);
  color: #fff;
  font-weight: 700;
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: auto;
  position: relative;
}
.upload_file_preview a {
  color: #000;
  text-decoration: none;
  font-size: 12px;
  margin: 0 5px;
}
.upload_file_preview svg {
  font-size: 16px;
}
.upload_file_preview span {
  display: flex;
  align-items: center;
}
.upload_file_preview {
  background: rgba(248, 248, 248, 1);
  border-radius: 42px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
}
.upload_file_preview a span {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.upload_doc input[type="file"] {
  width: 100%;
  height: 104%;
  position: absolute;
  padding: 0;
  top: 4px;
  left: 0;
  z-index: 1;
  opacity: 0;
}
body .MuiAutocomplete-wrap .MuiAutocomplete-inputRoot .MuiInputBase-input {
  font-size: 15px;
  padding: 6px 10px;
}
body .MuiAutocomplete-wrap .MuiAutocomplete-option {
  font-family: Poppins;
}
body .MuiAutocomplete-popper li {
  font-family: Poppins !important;
}
body .MuiAutocomplete-wrap .MuiAutocomplete-inputRoot {
  padding: 5px;
}
@media (max-width: 1650px) {
  body .dataCard {
    margin-right: 2.5%;
    max-width: 100%;
    flex-basis: 100%;
  }
  body .dataCard .dashboard-card p {
    font-size: 14px;
  }
  body .dataCard .dashboard-card h3 {
    font-size: 25px;
  }
  body .student_row .MuiTabs-root button {
    margin-right: 7px;
  }
}
@media (max-width: 1550px) {
  body .dataCard .dashboard-card p {
    font-size: 12px;
  }
  body .dataCard .dashboard-card h3 {
    font-size: 22px;
  }
}
@media (max-width: 1450px) {
  body .dataCard {
    flex-basis: 48% !important;
    max-width: 48% !important;
  }
  body .dataCard:nth-child(2n) {
    margin-right: 0;
  }
  body .mainData {
    max-width: 47%;
  }
  body .student_section {
    width: 60%;
  }
  /* MENU */
  .sidebar-menu ul.menu-wrap {
    margin: 40px 20px 0;
  }
  body .side-bar,
  body .side-bar .MuiPaper-root {
    width: 240px;
  }
  .dashboard-main .main-header-open {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
  .container {
    margin-top: 50px;
  }
  .course-content h1.main-heading {
    font-size: 35px;
  }
  .sidebar-menu ul.menu-wrap li.active ul.sub-menu li a {
    margin-left: 31px;
  }
  body .dashboard-main-close {
    margin-left: -240px;
  }
}

@media (max-width: 1350px) {
  body .student_section {
    width: 70%;
  }
}
@media (max-width: 1350px) {
  body .student_section {
    width: 80%;
  }
}
@media (max-width: 1280px) {
  body .profile_image_upload {
    height: 150px;
    width: 150px;
  }
  .upload-image label {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .editCourse .feeflex .MuiInputBase-root {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  body .side-bar .MuiPaper-root,
  body .side-bar {
    width: 240px;
  }
  .side-bar .main-logo img {
    width: 80%;
  }
  .sidebar-menu ul.menu-wrap {
    margin: 37px 15px 0;
  }
  /* .sidebar-menu ul.menu-wrap li.finances-icon.active .users-item {
    padding: 12px 82px 12px 0px;
  }
  .sidebar-menu ul.menu-wrap li.active .users-item {
    padding: 12px 110px 12px 0px;
  } */
  .dashboard-main .main-header-open {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  .dashboard-main header .top_header_inner a {
    font-size: 16px;
  }
  body .dashboard-main-close {
    margin-left: -240px;
  }
}

.due_date .react-datepicker-wrapper {
  width: 100%;
}
.due_date .react-datepicker-wrapper .react-datepicker__input-container {
  margin-top: 0;
}
.error-text-color p{color: red;margin-left: 0;}


.unauthorized-container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f1f1f1;
  color: #333;
}

.unauthorized-container {
  text-align: center;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.unauthorized-container h1 {
  font-size: 4rem;
  color: #e74c3c;
  margin-bottom: 10px;
}

.unauthorized-container p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.unauthorized-container a {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #033246;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.unauthorized-container a:hover {
  background-color: #033246;
  opacity: 0.8;
}