.table-head {
	background: linear-gradient(96.26deg, #001c28 -34.09%, #074e6c 110.58%);
}
.table-head .table-heading {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 23px;
	color: #fffefd;
	text-transform: capitalize;
	padding: 14px;
}
.table-head .table-heading:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.table-head .table-heading:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.table-body .table-cell {
	text-transform: capitalize;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #071836;
}
.table-body span.Mui-checked {
	color: #31373D;
}
.table-body span:hover {
	background-color: transparent;
}
.permission_wrap .cancle_btn{
    background: #C33F28;
    margin: 0 10px 0px 0px;
    text-transform: capitalize;
    padding: 10.2px 40px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    right: 167px;
    bottom: 24px;
}
.permission_wrap .cancle_btn:hover{
    background: #C33F28;
    opacity: 0.8;
}
.permission_wrap{
position: relative;
}
.permission_wrap .permission_table{
    margin-bottom: 30px;
    
}
.permission_wrap .btn-flex {
    margin-left: auto;
    padding-bottom: 25px;
}
.permission_wrap .role_name_ {
    color: #001C28;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
    margin-bottom: 8px;
    display: inline-block;
}
.permission_wrap .role_name {
    font-weight: 700;
}