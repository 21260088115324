.assignments_wrap .accordion_wrap {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 12px 14px 0px rgba(37, 10, 43, 0.02);
  margin-bottom: 25px;
  position: relative;
  padding: 0 25px 10px 25px;
}
.assignments_wrap .accordion_wrap [class*="MuiAccordionDetails-root"],
.assignments_wrap .accordion_wrap [class*="MuiAccordionSummary-root"] {
  padding: 0;
}
.assignments_wrap .accordion_wrap::before {
  display: none;
}
.assignments_wrap .accordion_wrap::after {
  content: "";
  width: 6px;
  height: 100%;
  border-radius: 5px;
  background: #004c6e;
  position: absolute;
  left: 0;
  top: 0;
}
.assignments_wrap .Weekly_Assignment_title {
  color: #001c28;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.assignments_wrap .Weekly_Assignment_title span {
  color: #00cf53;
}
.assignments_wrap .Assgniment_Title {
  color: #001c28;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.assignments_wrap .Assgniment_date {
  color: #001c28;
  font-family: Poppins;
  font-size: 14.73px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.assignments_wrap .marking {
  border-radius: 27.143px;
  background: rgba(0, 76, 110, 0.09);
  box-shadow: 0px 15.429px 18px 0px rgba(37, 10, 43, 0.02);
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 13.796px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
  width: 100%;
  max-width: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
}
.assignments_wrap .UnMarked {
  background-color: #001c28;
  color: #fff;
}

.accordion_wrap [class*="MuiAccordionSummary-content"] {
  align-items: center;
  margin: 15px 0;
}
.accordion_wrap .Assgniment_percentage {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.accordion_wrap .Assgniment_statuse {
  color: #00cf53;
  text-align: center;
  font-family: Poppins;
  font-size: 14.73px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.accordion_wrap .table_row {
  border-radius: 5px;
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
  box-shadow: 0px 34px 64px 0px rgba(0, 0, 0, 0.08);
}
.accordion_wrap .table_row th {
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 15.6px */
}
body .accordion_wrap .table_row_2 td {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.remarks_wrap .Remarks_title {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.remarks_wrap .remarks_content {
  color: #4e4e4e;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.75px;
  padding-bottom: 20px;
}
.remarks_wrap {
  padding-top: 25px;
}
