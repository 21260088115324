.display_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.display_right {
  display: flex;
}
.table_wrap {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 9px 2px rgba(37, 10, 43, 0.05);
  padding: 42px;
  padding-top: 32px;
}
body .popup h2 {
  padding-left: 0;
}
body .popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  max-height: 80vh;
}
.popup [class*="close_btn"] {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
  right: 30px;
  font-size: 0;
  border: 0;
  background: transparent;
  opacity: 1 !important;
  cursor: pointer;
  z-index: 999;
}
.popup [class*="resume"] {
  margin-left: 5px;
}

@media screen and (max-width: 1450px) {
  .table_wrap {
    padding: 25px;
    margin-top: 30px !important;
  }
}
