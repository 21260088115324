.course_content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  overflow-y: auto;
  max-height: 80vh;
}
.popup:focus-visible {
  outline: none;
}
.currency_rate_alert {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.currency_rate_alert a {
  text-decoration: none;
  border-radius: 4px;
  padding: 13.5px 24px;
  color: #fff;
  background: #c33f28;
  text-transform: capitalize;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}
.currency_rate_alert p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}
.currency_rate_alert h2 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0em;
  margin: 10px 0;
}
.currency_rate_alert p svg {
  margin-right: 15px;
}
.Currency_heding {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.symbol {
  position: absolute;
  top: 27px;
  right: 15px;
  font-family: Poppins !important;
  font-size: 13px;
  color: #8f979b;
}
.symbol_wrap {
  position: relative;
}
