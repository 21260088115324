.transactionsDetail .heading {
  color: #001c28;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 62px;
}

.transactionsDetail .transactionsId {
  color: #000;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 700;
  line-height: 40.8px;
}

.transactionsDetail .avatarName {
  color: #001c28;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 5px;
  text-transform: capitalize;
}
.transactionsDetail .INVOICE {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 55%;
  padding: 20px 0 20px 45px;
}
.transactionsDetail .subHeading {
  color: #001c28;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-right: 15px;
}
.transactionsDetail .avatarEmail {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.transactionsDetail .gradientBackground {
  min-height: 200px;
  /* background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%); */
  box-shadow: 0px 34px 64px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}
.transactionsDetail .infoWrap {
  margin-top: 32px;
}
.transactionsDetail .borderLeft {
  border-left: 1.5px solid rgb(254 254 254 / 29%);
  padding-left: 20px;
}
.transactionsDetail .ammoutWrap {
  border-radius: 9.6px;
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
  max-width: 335px;
  display: flex;
  align-items: center;
  width: 100%;
}
.transactionsDetail .label {
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.6px;
  background-color: #fff;
  display: inline-block;
  padding: 21px;
  border-radius: 9.6px;
  border: 2.5px solid #074e6c;
}
.transactionsDetail .note {
  color: #001c28;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.6px;
}
.transactionsDetail .amount {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 22.8px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.4px;
  margin-left: 23px;
}
.transactionsDetail .buttonWrap button {
  border-radius: 5px;
  background-color: #001c28;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 10px 22px;
}
.transactionsDetail .buttonWrap .buttonRed {
  background-color: #c33f28;
  margin-left: 17.5px;
}
.tractionForm button.refundButton {
  justify-content: flex-start;
  width: 100%;
  max-width: 120px;
  margin-right: auto;
}

body [class*="table-phases"].studentTransaction .refundButton {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  background-color: #001c28;
  padding: 6px 21.5px;
}
.infoTable .th_row {
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
}
.infoTable .th_row th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.infoTable .th_row th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.infoTable .th_row th {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
  border-bottom: 0;
}
.infoTable {
  margin-bottom: 28px;
}
.infoTable tbody tr {
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.infoTable tbody tr td {
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
}
.infoTable tbody tr .email_td {
  text-transform: inherit;
}
.header_wrap {
  justify-content: space-between;
  align-items: center;
}
.note_wrap {
  padding-right: 15px;
  width: calc(100% - 335px);
}
