/* .totalJobsContainer_wrap {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
} */

.totalJobsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px;
  position: relative;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
}
.totalJobsContainer:hover {
  background: linear-gradient(96deg, #001c28 -34.09%, #074e6c 110.58%);
}
.totalJobsContainer:hover .left_box h6,
.totalJobsContainer:hover .left_box h4 {
  color: #ffffff;
}
.totalJobsContainer:hover .imageContainer {
  background: #ffffff;
}
.totalJobsContainer:hover .imageContainer svg path {
  fill: #c33f28;
}
.imageContainer {
  margin-left: 20px;
  width: 59px;
  height: 59px;
  border-radius: 5px;
  background: #001c28;
  box-shadow: 0px 4px 9px 0px rgba(108, 58, 119, 0.06);
  padding: 12px;
}
body .totalJobsContainer .left_box h6 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
body .totalJobsContainer .left_box h4 {
  color: #001c28;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 43.2px;
}

.jobImage {
  max-width: 100%;
  height: auto;
}
.totalJobsContainer_ .btns {
  border-radius: 5px;
  background: #f1f5f6;
  color: #001c28;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.4px;
  margin-right: 10px;
  padding: 16px 0;
  transition: all 0.1s;
  max-width: 128.42px;
  width: 100%;
}
.totalJobsContainer_ .btns.active,
.totalJobsContainer_ .btns:hover {
  background: #fff;
  color: #c71903;
  font-weight: bold;
}
@media screen and (max-width: 1450px) {
  .totalJobsContainer {
    padding: 25px;
  }
}
@media screen and (max-width: 1199px) {
  .totalJobsContainer:after {
    height: 1px;
    width: 94%;
    right: 0;
    background: #edeced;
    bottom: 0;
    left: 0;
    margin: 0 auto;
  }
}
