.footer {
  padding-bottom: 12px;
}
.footer_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: #001c28;
}
.footer_links ul li {
  display: inline;
  list-style-type: none;
}
.footer_links ul li span {
  margin: 0px 35px;
}
.footer_links ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  text-align: center;
  color: #001c28;
}
.dashboard-footer {
  width: 100%;
  margin: 0px 0 0 12px;
  padding: 0;
  margin-top: auto;
}
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* position: fixed; */
  bottom: 0;
}
.footer p {
  color: #001c28;
}
.footer ul {
  margin: 0;
}
.footer ul li a {
  color: #001c28;
}
.footer_links {
  min-width: 635px;
  text-align: center;
}

@media(max-width:1650px){
  .footer_links { min-width: 435px; }
.footer_links ul li span { margin: 0px 18px; }
}
@media(max-width:1280px){
  .footer_links {min-width: 400px; }
}