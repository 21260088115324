.noticeBoardMain {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 24px 44px 0px rgba(37, 10, 43, 0.04);
  padding: 25px;
  background: #fff;
  overflow: hidden;
  width: 100%;
}
.noticeBoardMain .noticeBoardTitle {
  font-size: 21px;
  font-weight: 700;
  font-family: poppins;
  line-height: 2.5em;
}
.noticeBoardMain .noticeBoardListContent {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(180, 180, 180, 0.3);
}
.noticeBoardMain .noticeBoardListContent .noticeTextContent {
  padding: 0px;
}
.noticeBoardMain .noticeBoardListContent .noticeTextContent .noticeTitle {
  font-size: 13px;
}
.noticeBoardMain .noticeBoardListContent .noticeTextContent .noticeTitleBold {
  font-size: 13px;
  font-weight: 700;
  color: inherit;
}
.noticeBoardMain .noticeBoardListContent .noticeTimeContent {
  padding: 0px;
}
.noticeBoardMain .noticeBoardListContent .noticeTimeContent .noticeTime {
  font-size: 10px;
  color: inherit;
}
.noticeBoardMain .noticeBoardListContent:last-child {
  border-bottom: none;
}
body .noticeBoardMain_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .noticeBoardMain_header a {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(199, 25, 3, 1);
  text-decoration: none;
}
body .noticeBoardMain_header a:hover {
  opacity: 0.8;
}
body .noticeWrap [class*="rdt_TableHeadRow"] {
  display: none;
}
body .noticeWrap [class*="rdt_Table"] [data-column-id="1"] {
  padding: 0;
  font-family: Poppins;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
body .noticeWrap [class*="rdt_Table"] [data-column-id="2"] {
  justify-content: flex-end;
  padding: 0;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: right;
}
body .noticeWrap [class*="rdt_Table"] [id="row-9"] {
  display: none;
}

.Notifications_wrap {
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 3px 10px 2px rgba(37, 10, 43, 0.05);
  margin: 0 !important;
}
body .Notifications_wrap [class*="rdt_TableHeadRow"] {
  display: none;
}
body .Notifications_wrap [class*="rdt_Table"] [data-column-id="2"] {
  justify-content: flex-end;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
}
body .Notifications_wrap [class*="rdt_Table"] [data-column-id="1"] {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.Notifications_wrap_filter [class*="react-datepicker__input-container"] {
  margin: 0;
}
