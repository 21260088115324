.addStudent input {
  padding: 10px 15px;
}
.addStudent .locationFields [aria-haspopup="listbox"] {
  padding: 10px 15px;
}
body .preAssessmentFormContainer [class*="Mui-error"] {
  margin-left: 0;
}
.preAssessmentFormContainer {
  width: 95%;
}
body .preAssessmentFormContainer .addStudent {
  padding: 30px 60px;
  margin-bottom: 40px;
}
body .preAssessmentFormContainer [class*="MuiFormControlLabel-label"] {
  font-family: "Poppins";
}
body .preAssessmentFormContainer [class*="btn-flex"] {
  justify-content: flex-end;
}
body .preAssessmentFormContainer .box {
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 16px;
}
body .PreAssessmentFormView .btn {
  margin: 0 10px 0px 0px;
  text-transform: capitalize;
  padding: 10px 40px;
  font-weight: 700;
  background: #c33f28;
}

body .PreAssessmentFormView .btn:hover {
  background: #c33f28;
  opacity: 0.8;
}
body .PreAssessmentFormView .label_key {
  text-transform: capitalize;
}
body .PreAssessmentFormView .box ul {
  list-style-type: decimal;
  font-weight: 700;
}
body .PreAssessmentFormView [class*="cnic"] {
  text-transform: uppercase;
}

@media (max-width: 980px) {
  body .preAssessmentFormContainer .addStudent {
    padding: 20px 30px;
  }
  .preAssessmentFormContainer h1 {
    font-size: 32px;
  }
}
