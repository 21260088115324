form .btn {
  margin: 0 10px 0px 0px;
  text-transform: capitalize;
  padding: 10px 40px;
  font-weight: 700;
}
form .btn-flex {
  display: flex;
  justify-content: end;
}
form .save {
  background: #c33f28;
}
form .save:hover {
  background: #c33f28;
  opacity: 0.8;
}
form .cancel {
  background: #001c28;
}
form .cancel:hover {
  background: #001c28;
  opacity: 0.8;
}
