/* Main Modal Styling */


.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.modalTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
}
.historyTitle {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.historyTitle .id{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
}

.history_list li{
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 22.1px;
  text-align: left;
  
}

.modalBody {
  display: flex;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 25px;
}

/* Description Styling */
.description {
  margin-bottom: 20px;
}
.description p{
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  }

.descriptionContent {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.6;
}

/* History and Personal Data */
.history, 
.personalData {
  margin-bottom: 24px;
}
.personalData {
  padding: 35px;
  width: 33%;
}

.historyItem, .personalDataItem {
  margin-bottom: 12px;
}

.historyItem strong, .personalDataItem strong {
  font-weight: bold;
  color: #333;
}

.historyItem p, .personalDataItem p {
  font-size: 0.875rem;
  color: #555;
}

.attachmentList {
  margin-top: 10px;
  list-style-type: none;
  padding: 0;
}

.attachmentList li {
  font-size: 0.85rem;
  color: #007bff;
}

.attachmentList a {
  text-decoration: none;
  color: #007bff;
}

.attachmentList a:hover {
  text-decoration: underline;
}

.comments {
  margin-top: 16px;
}

.comment {
  background: rgba(248, 248, 248, 1);
  padding: 15px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 22.1px;
  margin-bottom: 10px;

}

/* Priority, Status, and Dates */
.priorityHigh {
  background-color: #ff5252;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.75rem;
  display: inline-block;
  margin-left: 8px;
}

.statusCompleted {
  color: #4caf50;
  font-weight: bold;
}

.statusActive {
  color: #ff9800;
  font-weight: bold;
}
.historyCol{
  width: 67%;
 border-right: 1px solid #f0f0f0;
 padding: 35px 33px;
}
.modalContainer h2{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  margin: 15px 35px;  
}

body .iconButtonGroup {
  display: flex;
  justify-content: center;
  gap: 16px;
}

body .iconButton {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 4px;
}

body .iconButton:hover {
  background-color: #e0e0e0;
}

body .icon {
  color: #000;
  font-size: 12px;
}
.history_wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.history_wrap .modalTitle{
  margin-bottom: 0;
}
.comment_title{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  margin: 0;
  margin-bottom: 22px;
}
.comment_tagline{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.Popover_list {
  margin: 0;
  padding: 10px 0;
  background: rgba(242, 242, 242, 1);
}
.Popover_list li{
  list-style-type: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 25.5px;
  padding: 0 16px;
  transition: all 0.8s;
}
.Popover_list li:hover{
  background-color: #00000069;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modalContainer {
    padding: 16px;
    width: 100%;
  }

  .modalBody {
    flex-direction: column;
    gap: 12px;
  }
}
