body .col .col_wrap {
  border-radius: 10px;
  background: #fff;
  min-height: 200px;
  padding: 50px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 9px 0px rgba(37, 10, 43, 0.05);
  position: relative;
}
body .col .Link {
  position: absolute;
  width: 100%;
  height: 100%;
}

body .col .col_wrap h3 {
  color: #001c28;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  text-decoration: none;
}

body .col .col_wrap h4 {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.8px;
  margin: 10px 0;
}
body .col .col_wrap p {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
body .col .col_wrap h6 {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 22px 0;
}
body .col .col_wrap h6 span {
  font-weight: 700;
}
.small_box .key {
  color: #001c28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-right: 10px;
}
.small_box {
  border-radius: 5px;
  background: #edeced;
  max-width: 140.993px;
  padding: 6px 10px;
  margin-top: 30px;
}
.small_box .value {
  color: #001c28;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.2px;
}

.edit_wrap .active {
  border-radius: 10px;
  background: #001c28;
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 10px;
  line-height: 0;
  display: flex;
  align-items: center;
  height: 18px;
}
