body .main_heading {
  margin-bottom: 50px;
  color: #001c28;
  font-weight: 700;
  font-size: 40px;
  font-family: Poppins;
}
body .wlcm_heading {
  margin-bottom: 50px;
  color: #001c28;
  font-weight: 400;
  font-size: 40px;
  font-family: Poppins;
}
body .wlcm_heading span {
  font-weight: 600;
  color: #c33f28;
}
